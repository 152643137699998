import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { base_url } from "../../utils/apis";
import store from "../Store";

const loggedInUser = localStorage.getItem("user");
const user = JSON.parse(loggedInUser);

export const getNotifications = createAsyncThunk("notification", async (data) => {
  try {
    const body = new FormData();
    const loggedInUser = localStorage.getItem("user");
const user = JSON.parse(loggedInUser);
    body.append("customerid", data?.id || user?.userid);
    body.append("type", "user");
    if(data?.type){
    body.append("listtype", data?.type);
    }
    const responce = await fetch(base_url + "notificationlist.php", {
      method: "POST",
      body: body,
    });
    const res = await responce.json();

    return res;
  } catch (error) {}
});

// export const getNotifications = createAsyncThunk("notification", async (id) => {
//   try {
//     const body = new FormData();
//     body.append("customerid", id);
//     body.append("type", "user");
//     const responce = await fetch(base_url + "notificationlist.php", {
//       method: "POST",
//       body: body,
//     });
//     const res = await responce.json();

//     return res;
//   } catch (error) {}
// });
export const readNotification = createAsyncThunk(
  "readnotification",
  async (data) => {
    try {
      const body = new FormData();
      body.append("customerid", data.c_id);
      body.append("id", data.id);
      body.append("status", 1);
      const responce = await fetch(base_url + "notification-status.php", {
        method: "POST",
        body: body,
      });
      const res = await responce.json();
      if (res.success) {
        store.dispatch(getNotifications({id:data.c_id}));
      } else {
      }
    } catch (error) {}
  }
);

const notificationSlice = createSlice({
  name: "notification",
  initialState: {
    notification_list: [],
    loading: false,
    unread_count: "",
    unread_policy_count:"",
    unread_document_count:"",
  },
  extraReducers: (builder) => {
    builder.addCase(getNotifications.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getNotifications.fulfilled, (state, action) => {
      state.loading = false;
      const data = action.payload;
      if (data.success) {
        state.notification_list =
          data.data == "No data Avaialable" ? [] : data.data;
        state.unread_count = data.unread;
        state.unread_policy_count = data.unread_policy;
        state.unread_document_count = data.unread_document;
      } else {
        state.user_data = [];
      }
    });
  },
});
export default notificationSlice.reducer;
