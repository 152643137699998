import { Base64 } from "js-base64";

import { toast } from "react-toastify";
import existingPolicyImg from '../images/ExistingPolicy.svg' 
import ArchivedImg from '../images/ArchivedPolicies.svg' 
import ClaimsImg from '../images/Claims.svg' 
import BuyAPolicyImg from '../images/BuyAPollicy.svg' 
import uploadImg from '../images/upload-window_svgrepo.com.svg' 
import uploadDocsImg from '../images/file_docs_upload.svg' 
import DocumnetsImg from '../images/Documents.svg' 
export const passwordRegex = /^(?=.*?[a-z])(?=.*?[#?!@$%^&*-]).{8,}$/;
export const emailRegex =
  /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;
// export const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;


export const homePageData = [
  {
    img:existingPolicyImg,
    lable:"Insurance Policies",
    path:"/policy",
  },
  {
    img:DocumnetsImg,
    lable:"Documents",
    path:"/document",
  },
  {
    img:ClaimsImg,
    lable:"Claims Assist",
    path:"/claims",
  },
  {
    img:uploadImg,
    lable:"Upload Policy",
    path:"/add-policy",
  },
  {
    img:uploadDocsImg,
    lable:"Upload Document",
    path:"/add-document",
  },
  // {
  //   img:ArchivedImg,
  //   lable:"Archived Policies",
  //   path:"/archived-policies",
  // },
  {
    img:BuyAPolicyImg,
    lable:"Buy a Pollicy",
    path:"/buy-policy",
  },
  
]

export const successMess = (message) => {
  toast.success(message, {
    toastId: "1",
  });
};
export const errorMess = (message) => {
  toast.error(message, {
    toastId: "1",
  });
};

export const infoMess = (message) => {
  toast.info(message, {
    toastId: "1",
  });
};

// export const timer=()=>{

//     const [seconds, setSeconds] = useState(60);

//   useEffect(() => {
//     // Create a function that will decrement the timer every second
//     const timer = setInterval(() => {
//       if (seconds > 0) {
//         setSeconds(seconds - 1);
//       }
//     }, 1000);

//     // Clean up the timer when the component unmounts or when seconds reach 0
//     return () => {
//       clearInterval(timer);
//     };
//   }, [seconds]);

// }
export const getMyLocation = async () => {
  const location = window.navigator && window.navigator.geolocation;

  if (location) {
    location.getCurrentPosition(
      (position) => {
        return {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        };
      },
      (error) => {
        return false;
      },
      { enableHighAccuracy: true, timeout: 20000, maximumAge: 1000 }
    );
  }
};

// export const encryptData = (data) => {
//   const secretKey = "YourSecretKey";
//   const encryptedData = AES.encrypt(data, secretKey).toString();
//   return encryptedData;
// };
// export const decryptData = (data) => {
//   const secretKey = "YourSecretKey";
//   const decryptedData = AES.decrypt(data, secretKey);
//   const utf8Data = decryptedData.toString(CryptoJS.enc.Utf8);
//   const base64Data = btoa(utf8Data);
//   return base64Data;
// };
export const encryptData = (data) => {
  const randomChars = Array.from(
    { length: 2 },
    () => String.fromCharCode(Math.floor(Math.random() * 26) + 97) // Generates random lowercase letters
  ).join("");
  const randomChars1 = Array.from(
    { length: 2 },
    () => String.fromCharCode(Math.floor(Math.random() * 26) + 97) // Generates random lowercase letters
  ).join("");
  const encryptedData = Base64.encode(data);

  return randomChars + encryptedData + randomChars1;
};
export const decryptData = (str = "") => {
  if (str.length < 4) {
    // The string is too short to remove 2 characters from both the start and end.
    return str;
  }

  // Remove 2 characters from the start and end
  const result = str.substring(2, str.length - 2);
  const decryptedData = Base64.decode(result);
  return decryptedData;
};

const loggedInUser = localStorage.getItem("user");
const user = JSON.parse(loggedInUser);
export const ID = user?.userid;

export const DownloadPDFfile = (url) => {
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", true);
  link.setAttribute("target", "_blank");
  document.body.appendChild(link);
  link.click();
};

export const downloadImage = async (originalImage) => {
  const image = await fetch(originalImage);
  const nameSplit = originalImage.split("/");
  const duplicateName = nameSplit.pop();

  const imageBlog = await image.blob();
  const imageURL = URL.createObjectURL(imageBlog);
  const link = document.createElement("a");
  link.href = imageURL;
  link.download = "" + duplicateName + "";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

// export const downloadImage = (imageUrl) => {
//   fetch(imageUrl)
//     .then((response) => response.blob())
//     .then((blob) => {
//       // Create a temporary anchor element
//       const url = window.URL.createObjectURL(blob);
//       const link = document.createElement("a");
//       link.href = url;

//       // Extract the filename from the URL
//       const filename = imageUrl.substring(imageUrl.lastIndexOf("/") + 1);

//       // Set the download attribute and filename
//       link.setAttribute("download", filename);
//       document.body.appendChild(link);

//       // Simulate a click on the anchor element to start the download
//       link.click();

//       // Clean up the temporary anchor element
//       link.parentNode.removeChild(link);

//       // Set the downloaded image URL to display on the page
//       // setImageUrl(url);
//     })
//     .catch((error) => {
//       console.error("Error downloading image:", error);
//     });
// };
