import "./App.css";
import {
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation,
  Link,
} from "react-router-dom";
import Singup from "./screens/Singup";
import Addpolicy from "./screens/policy/Addpolicy";
import Adddocument from "./screens/documents/Adddocument ";
import Notificationlist from "./screens/Notificationlist";
import React, { useEffect, useRef, useState } from "react";
import ProtectedRoute from "./ProtectedRoute";
import Login from "./screens/Login";
import Layout from "./components/Layout";
import Home from "./screens/home/Home";
import Editpolicy from "./screens/policy/EditPolicy";
import EditDocument from "./screens/documents/EditDocument";
import Profile from "./screens/setting/Profile";
import AskanExpert from "./screens/setting/AskanExpert";
import Claims from "./screens/setting/Claims";
import BuyPolicy from "./screens/setting/BuyPolicy";
import NotificationDetail from "./screens/NotificationDetail";
import ArchivedPolicy from "./screens/setting/ArchivedPolicy";
import ResetPassword from "./screens/ResetPassword";
import AskExpert from "./components/AskExpert";
import { Center, Container, Heading, Stack, Text } from "@chakra-ui/react";
import CustomButton1 from "./components/CustomButton1";
import CustomPopup from "./components/CustomPopup";
import store from "./redux/Store";
import {
  getUserData,
  setSessionOut,
  setUser_id,
} from "./redux/slice/userSlice";
import { useSelector } from "react-redux";
import { getDToken, onMessageListener } from "./firebase";
import { getNotifications } from "./redux/slice/notificationSlice";
import { NotificationManager } from "react-notifications";
import Documents from "./screens/home/Documents";
import Policy from "./screens/home/Policy";
import { get_background_image, get_video } from "./redux/slice/OtherSlice";
import DeleteAccount from "./screens/DeleteAccount";

function App() {
  const navigate = useNavigate();
  const [isOnline, setIsOnline] = useState(window.navigator.onLine);
  const loggedInUser = localStorage.getItem("user");
  const user = JSON.parse(loggedInUser);
  const ID = user?.userid;
  const { sessionOut, user_id } = useSelector((state) => state.user);
  const [shows, setShows] = useState(true);
  const location = useLocation();
  const pathname = location?.pathname;
  useEffect(() => {
    const loggedInUser = localStorage.getItem("user");
    const user = JSON.parse(loggedInUser);
    store.dispatch(setUser_id(user?.userid));
    store.dispatch(get_background_image());
    store.dispatch(get_video());
    // store.dispatch(getUserData(ID));
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    if (user === null) {
      <Navigate to="/login" replace />;
      return;
    }
    <Navigate to="/" replace />;
  }, []);

  useEffect(() => {
    const handleStatusChange = () => {
      store.dispatch(getNotifications({ id: user?.userid }));
    };
    window.addEventListener("focus", handleStatusChange);
    return () => {
      window.removeEventListener("focus", handleStatusChange);
    };
  }, [user]);

  useEffect(() => {
    const handleStatusChange = () => {
      setIsOnline(window.navigator.onLine);
    };
    window.addEventListener("online", handleStatusChange);
    window.addEventListener("offline", handleStatusChange);

    return () => {
      window.removeEventListener("online", handleStatusChange);
      window.removeEventListener("offline", handleStatusChange);
    };
  }, [isOnline]);
  const [sessionExpire, setSessionExpire] = useState(false);

  const onTabClose = () => {
    const check = performance.getEntriesByType("navigation")[0]?.type;
    if (check === "reload") {
    } else {
      localStorage.removeItem("user");
      localStorage.removeItem("fbToken");
    }
  };

  useEffect(() => {
    onTabClose();
  }, []);

  // Session Timeout Code Start

  const [timeout, setTimeout] = useState(600);
  const timerRef = useRef(null);


  const resetTimer = () => {
    clearInterval(timerRef.current);
    setTimeout(600);
    startTimer();
  };

  const startTimer = () => {
    timerRef.current = setInterval(() => {
      setTimeout((prev) => prev - 1);
    }, 1000);
  };

  const handleVisibilityChange = () => {
    if (!document.hidden) {
      clearInterval(timerRef.current);
    } else {
      startTimer();
    }
  };

  useEffect(() => {
    const handleUserActivity = () => {
      resetTimer();
    };
    startTimer();
    document.addEventListener("keydown", handleUserActivity);
    document.addEventListener("mousemove", handleUserActivity);
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("keydown", handleUserActivity);
      document.removeEventListener("mousemove", handleUserActivity);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      clearInterval(timerRef.current);
    };
  }, []);

  // Session Timeout Code Start

  useEffect(() => {
    if (timeout === 0 || timeout <= 0 ) {
      if (user != null) {
        localStorage.clear();
        setSessionExpire(true);
      }
    }
  }, [timeout]);

  // Firbase Message

  useEffect(() => {
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        getDToken();
      } else {
        // infoMess("Unable to get permission to notify.");
      }
    });
  }, []);
  onMessageListener()
    .then((payload) => {
      store.dispatch(getNotifications({ id: user.userid }));
      NotificationManager.success(
        payload.notification.body,
        payload.notification.title,
        2000,
        () => navigate("/all-notifications")
      );
    })
    .catch((err) => console.log("failed: ", err));

  // End Firbase


  return (
    <>
      {isOnline ? (
        <Routes>
          <Route element={<ProtectedRoute />}>
            <Route path="/" element={<Layout />}>
              <Route index element={<Home />} />
              <Route path="policy" element={<Policy />} />
              <Route path="add-policy" element={<Addpolicy />} />
              <Route path="edit-policy" element={<Editpolicy />} />
              <Route path="document" element={<Documents />} />

              <Route path="add-document" element={<Adddocument />} />
              <Route path="edit-document" element={<EditDocument />} />
              <Route path="all-notifications" element={<Notificationlist />} />
              <Route
                path="notification-detail"
                element={<NotificationDetail />}
              />
              <Route path="profile" element={<Profile />} />
              <Route path="ask-an-expert" element={<AskanExpert />} />
              <Route path="claims" element={<Claims />} />
              <Route path="buy-policy" element={<BuyPolicy />} />
              <Route path="archived-policies" element={<ArchivedPolicy />} />
            </Route>
          </Route>
          <Route path="login" element={<Login />} />
          <Route path="singup" element={<Singup />} />
          <Route path="delete-account" element={<DeleteAccount />} />
          <Route path="reset-password" element={<ResetPassword />} />
          <Route path="/404" element={<PageNotFound />} />
          <Route path="*" element={<Navigate to="/404" />} />
        </Routes>
      ) : (
        <NoInternet />
      )}
      {location.pathname == "/login" ||
        location.pathname == "/singup" ||
        location.pathname == "/reset-password" || (
          <Stack
            position={"fixed"}
            zIndex={1000}
            bottom={["20", "20"]}
            right={["2", "10"]}
            display={["flex", "none"]}
          >
            {/* <AskExpert bg={"#e0a604"} /> */}
          </Stack>
        )}
      <CustomPopup
        isOpen={sessionExpire}
        onClose={() => {
          setSessionExpire(false);
          localStorage.clear();
          window.sessionStorage.clear();
          sessionStorage.clear();
          store.dispatch(setSessionOut(false));
          store.dispatch(setUser_id(""));
          window.location.reload();
        }}
        title={"Alert"}
        mess={"Your session is expired please Login"}
        single_button={true}
        onClick={() => {
          setSessionExpire(false);
          window.sessionStorage.clear();
          localStorage.clear();
          store.dispatch(setSessionOut(false));
          store.dispatch(setUser_id(""));
          window.location.reload();
        }}
        b_name={"OK"}
      />
    </>
  );
}
function NoInternet() {
  return (
    <Container
      bg={"#f8f9fe"}
      display={"flex"}
      h={"100vh"}
      maxW={"100vw"}
      alignItems={"center"}
      justifyContent={"center"}
      flexDirection={"column"}
    >
      {/* <Image src={nointernet} w={"lg"} objectFit={"cover"} h={"250px"} /> */}
      <Heading color={"#e0a604"}>No Internet Connection</Heading>
      <Text my={4}>Please check your connection</Text>
      <Center>
        <CustomButton1
          title={"Try Again"}
          onClick={() => window.location.reload()}
        />
      </Center>
    </Container>
  );
}
function PageNotFound() {
  return (
    <div
      style={{
        height: "100vh",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      }}
    >
      <div style={{ textAlign: "center" }}>
        <Heading fontSize={"6xl"} color={"#e0a604"}>
          404
        </Heading>
        <Heading fontSize={"2xl"} paddingBlock={2}>
          Page Not Found
        </Heading>
        <Text>Oops! You seem to be lost.</Text>
        <Text>
          Go to
          <Link
            to="/"
            style={{
              textDecorationLine: "underline",
              padding: 5,
            }}
          >
            {" "}
            Home{" "}
          </Link>
          page
        </Text>
      </div>
    </div>
  );
}
export default App;

// import "./App.css";
// import {
//   Routes,
//   Route,
//   Navigate,
//   useNavigate,
//   useLocation,
//   Link,
// } from "react-router-dom";
// import Singup from "./screens/Singup";
// import Addpolicy from "./screens/policy/Addpolicy";
// import Adddocument from "./screens/documents/Adddocument ";
// import Notificationlist from "./screens/Notificationlist";
// import React, { useEffect, useRef, useState } from "react";
// import ProtectedRoute from "./ProtectedRoute";
// import Login from "./screens/Login";
// import Layout from "./components/Layout";
// import Home from "./screens/home/Home";
// import Editpolicy from "./screens/policy/EditPolicy";
// import EditDocument from "./screens/documents/EditDocument";
// import Profile from "./screens/setting/Profile";
// import AskanExpert from "./screens/setting/AskanExpert";
// import Claims from "./screens/setting/Claims";
// import BuyPolicy from "./screens/setting/BuyPolicy";
// import NotificationDetail from "./screens/NotificationDetail";
// import ArchivedPolicy from "./screens/setting/ArchivedPolicy";
// import ResetPassword from "./screens/ResetPassword";
// import AskExpert from "./components/AskExpert";
// import { Center, Container, Heading, Stack, Text } from "@chakra-ui/react";
// import CustomButton1 from "./components/CustomButton1";
// import CustomPopup from "./components/CustomPopup";
// import store from "./redux/Store";
// import {
//   getUserData,
//   setSessionOut,
//   setUser_id,
// } from "./redux/slice/userSlice";
// import { useSelector } from "react-redux";

// function App() {
//   const navigate = useNavigate();
//   const [isOnline, setIsOnline] = useState(window.navigator.onLine);
//   const loggedInUser = localStorage.getItem("user");
//   const user = JSON.parse(loggedInUser);
//   const { sessionOut, user_id } = useSelector((state) => state.user);
//   const [shows, setShows] = useState(true);
//   const location = useLocation();
//   useEffect(() => {
//     const loggedInUser = localStorage.getItem("user");
//     const user = JSON.parse(loggedInUser);
//     store.dispatch(getUserData(user_id));
//   }, []);
//   useEffect(() => {
//     if (user === null) {
//       <Navigate to="/login" replace />;
//       return;
//     }
//     <Navigate to="/" replace />;
//   }, []);

//   useEffect(() => {
//     const handleStatusChange = () => {
//       setIsOnline(window.navigator.onLine);
//     };
//     window.addEventListener("online", handleStatusChange);
//     window.addEventListener("offline", handleStatusChange);

//     return () => {
//       window.removeEventListener("online", handleStatusChange);
//       window.removeEventListener("offline", handleStatusChange);
//     };
//   }, [isOnline]);
//   const [activityTimer, setActivityTimer] = useState(null);
//   const [sessionExpire, setSessionExpire] = useState(false);

//   const handleUserActivity = () => {
//     clearTimeout(activityTimer);

//     const newActivityTimer = setTimeout(() => {
//       if (sessionOut == true) {
//         setSessionExpire(true);
//         localStorage.clear();
//       } else {
//       }
//     }, 10 * 60 * 1000); // 10 minutes in milliseconds
//     setActivityTimer(newActivityTimer);
//   };

//   useEffect(() => {
//     // Initialize the activity timer when the component mounts.
//     handleUserActivity();
//     // Add event listeners for user activity.
//     window.addEventListener("mousemove", handleUserActivity);
//     window.addEventListener("keydown", handleUserActivity);
//     return () => {
//       // Clean up event listeners when the component unmounts.
//       window.removeEventListener("mousemove", handleUserActivity);
//       window.removeEventListener("keydown", handleUserActivity);
//     };
//   }, [sessionOut]); // Empty dependency array ensures that the effect runs once on mount

//   const onTabClose = () => {
//     const check = performance.getEntriesByType("navigation")[0]?.type;
//     if (check === "reload") {
//       // localStorage.setItem("user", JSON.stringify(user));
//       // Add your logic for page reload
//     } else {
//       localStorage.removeItem("user");
//       // Add your logic for tab close
//     }
//   };

//   useEffect(() => {
//     onTabClose();
//   }, []);
//   return (
//     <>
//       {isOnline ? (
//         <Routes>
//           <Route element={<ProtectedRoute />}>
//             <Route path="/" element={<Layout />}>
//               <Route index element={<Home />} />
//               <Route path="add-policy" element={<Addpolicy />} />
//               <Route path="edit-policy" element={<Editpolicy />} />
//               <Route path="add-document" element={<Adddocument />} />
//               <Route path="edit-document" element={<EditDocument />} />
//               <Route path="all-notifications" element={<Notificationlist />} />
//               <Route
//                 path="notification-detail"
//                 element={<NotificationDetail />}
//               />
//               <Route path="profile" element={<Profile />} />
//               <Route path="ask-an-expert" element={<AskanExpert />} />
//               <Route path="claims" element={<Claims />} />
//               <Route path="buy-policy" element={<BuyPolicy />} />
//               <Route path="archived-policies" element={<ArchivedPolicy />} />
//             </Route>
//           </Route>
//           <Route path="login" element={<Login />} />
//           <Route path="singup" element={<Singup />} />
//           <Route path="reset-password" element={<ResetPassword />} />
//           <Route path="/404" element={<PageNotFound />} />
//           <Route path="*" element={<Navigate to="/404" />} />
//         </Routes>
//       ) : (
//         <NoInternet />
//       )}
//       {location.pathname == "/login" ||
//         location.pathname == "/singup" ||
//         location.pathname == "/reset-password" || (
//           <Stack
//             position={"fixed"}
//             zIndex={1000}
//             bottom={["4", "20"]}
//             right={["2", "10"]}
//             display={["flex", "none"]}
//           >
//             <AskExpert bg={"#e0a604"} />
//           </Stack>
//         )}
//       <CustomPopup
//         isOpen={sessionExpire}
//         onClose={() => {
//           setSessionExpire(false);
//           localStorage.clear();
//           window.sessionStorage.clear();
//           sessionStorage.clear();
//           store.dispatch(setSessionOut(false));
//           store.dispatch(setUser_id(""));
//           window.location.reload();
//         }}
//         title={"Alert"}
//         mess={"Your session is expired please Login"}
//         single_button={true}
//         onClick={() => {
//           setSessionExpire(false);
//           window.sessionStorage.clear();
//           localStorage.clear();
//           // localStorage.removeItem("user");
//           store.dispatch(setSessionOut(false));
//           store.dispatch(setUser_id(""));
//           window.location.reload();
//         }}
//         b_name={"OK"}
//       />
//     </>
//   );
// }
// function NoInternet() {
//   return (
//     <Container
//       bg={"#f8f9fe"}
//       display={"flex"}
//       h={"100vh"}
//       maxW={"100vw"}
//       alignItems={"center"}
//       justifyContent={"center"}
//       flexDirection={"column"}
//     >
//       {/* <Image src={nointernet} w={"lg"} objectFit={"cover"} h={"250px"} /> */}
//       <Heading color={"#e0a604"}>No Internet Connection</Heading>
//       <Text my={4}>Please check your connection</Text>
//       <Center>
//         <CustomButton1
//           title={"Try Again"}
//           onClick={() => window.location.reload()}
//         />
//       </Center>
//     </Container>
//   );
// }
// function PageNotFound() {
//   return (
//     <div
//       style={{
//         height: "100vh",
//         width: "100%",
//         justifyContent: "center",
//         alignItems: "center",
//         display: "flex",
//       }}
//     >
//       <div style={{ textAlign: "center" }}>
//         <Heading fontSize={"6xl"} color={"#e0a604"}>
//           404
//         </Heading>
//         <Heading fontSize={"2xl"} paddingBlock={2}>
//           Page Not Found
//         </Heading>
//         <Text>Oops! You seem to be lost.</Text>
//         <Text>
//           Go to
//           <Link
//             to="/"
//             style={{
//               textDecorationLine: "underline",
//               padding: 5,
//             }}
//           >
//             {" "}
//             Home{" "}
//           </Link>
//           page
//         </Text>
//       </div>
//     </div>
//   );
// }
// export default App;



  // const [activityTimer, setActivityTimer] = useState(null);
  // const handleUserActivity = (item) => {
  //   clearTimeout(activityTimer);

  //   const newActivityTimer = setTimeout(() => {
  //     if (sessionOut == true) {
  //       setSessionExpire(item);
  //       localStorage.clear();
  //     } else {
  //     }
  //   }, 10 * 60 * 1000); // 10 minutes in milliseconds
  //   setActivityTimer(newActivityTimer);
  // };

  // useEffect(() => {
  //   // Initialize the activity timer when the component mounts.
  //   handleUserActivity(true);
  //   // Add event listeners for user activity.
  //   window.addEventListener("mousemove", handleUserActivity(true));
  //   window.addEventListener("keydown", handleUserActivity(true));
  //   return () => {
  //     window.removeEventListener("mousemove", handleUserActivity(false));
  //     window.removeEventListener("keydown", handleUserActivity(false));
  //   };
  // }, [sessionOut]);