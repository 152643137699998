import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";
import React from "react";

const CustomModal = ({ body, isOpen, onClose, w, h }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        maxW={w || "container.md"}
        maxH={h || "xl"}
        h={"full"}
        w={"full"}
      >
        <ModalCloseButton zIndex={100} bg={"#99999988"} />
        <ModalBody h={"full"} overflow={"scroll"}>
          {body}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CustomModal;
