import {
  Center,
  Container,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React from "react";

const TableView = ({ headData = [], body }) => {
  return (
    <TableContainer
      bg={"#fff"}
      borderRadius={5}
      flex={1}
      maxWidth={"full"}
      w={"full"}
      overflow={"scroll"}
      border={"1px solid #88888822"}
      shadow={"sm"}
    >
      <Table variant="simple">
        <Thead bg={"#88888822"}>
          <Tr>
            {headData.map((v, i) => (
              <Th color={"#000"} key={i} textAlign={"center"}>
                {v}
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>{body}</Tbody>
      </Table>
    </TableContainer>
  );
};

export default TableView;
