import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import CustomButton1 from "./CustomButton1";

const Popup = ({ open, setOpen, title, mess }) => {
  const cancelRef = React.useRef();
  // const [show, setShow] = useState(open);

  return (
    <AlertDialog
      isOpen={open}
      leastDestructiveRef={cancelRef}
      size={"2xl"}
      //   onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {title}
          </AlertDialogHeader>

          <AlertDialogBody>{mess}</AlertDialogBody>
          <AlertDialogFooter>
            <CustomButton1 bg={"gray"} title={"OK"} onClick={setOpen} />
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default Popup;
