import { Flex, HStack, Image, Stack, Text } from "@chakra-ui/react";
import React from "react";
import NotificationHeader from "./NotificationHeader";
import Setting from "./Setting";
import { useNavigate } from "react-router-dom";
import AskExpert from "./AskExpert";
import { IoIosHelpCircle } from "react-icons/io";

const Header = () => {
  const navigate = useNavigate();
  return (
    <Stack
      w={"full"}
      px={["2", "5", "10", "20"]}
      display={"flex"}
      flexDirection={"row"}
      alignItems={"center"}
      justify={"space-between"}
      bg={"rbg(0,0,0,.5)"}
    >
      <Image
        src="Logo2.png"
        w={["90px", "100px", "187px"]}
        borderRadius={3}
        onClick={() => navigate("/")}
        cursor={"pointer"}
      />
      <Flex gap={[2,"5"]} alignItems={"center"}>
        <Stack display={["flex", "flex"]}>
          <AskExpert />
        </Stack>
        <NotificationHeader />
        <Setting />
      </Flex>
    </Stack>
  );
};

export default Header;
