import { Center, Container, HStack, Stack, Text } from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CustomButton1 from "../../components/CustomButton1";
import SelectImage from "../../components/SelectImage";
import CustomInput from "../../components/CustomInput";
import BreadCrumName from "../../components/BreadCrumName";
import { addUserDocument } from "../../utils/apis";
import { ID, infoMess } from "../../utils/utils";
import Select from "react-select";
import moment from "moment";
import { BiArrowBack } from "react-icons/bi";
import { useSelector } from "react-redux";
import SelectBox from "../../components/SelectBox";

const EditDocument = () => {
  const loggedInUser = localStorage.getItem("user");
  const user = JSON.parse(loggedInUser);
  const ID = user?.userid;
  const { user_id } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const location = useLocation();
  const [d_data, setD_data] = useState({});
  const [loading, setLoading] = useState(false);
  const [path, setPath] = useState("");
  const add_document = () => {
    if (!d_data.name.trim()) {
      infoMess("Please fill all fields");
      return;
    }

    const char = d_data?.name?.trim();
    const firstChar = char?.charAt(0);
     
    if (/^[^a-zA-Z]+$/.test(firstChar)) {
      infoMess("Name could not contain special characters or spaces at the beginning");
      return;
    }
    const body = new FormData();
    body.append("action", "update");
    body.append("userid", ID);
    body.append("id", location.state.id);
    body.append("doctype", d_data.type);
    body.append("docname", d_data.name.trim());
    (d_data.type !== "Aadhar" || d_data.type !== "PAN") &&
      body.append("expiry", d_data.date);
    {
      d_data?.img && body.append("docfile", d_data.img);
    }
    addUserDocument(body, setLoading).then(
      (v) => v.success && navigate("/", { replace: true })
    );
  };

  useEffect(() => {
    setD_data({
      ...d_data,
      type: location.state.doc_type,
      name: location.state.Name,
      c_img: location.state.document,
      date: location.state.expiry_date,
    });
  }, []);
  const options = [
    { value: "Aadhar", label: "Aadhar" },
    { value: "PAN", label: "PAN" },
    { value: "RC", label: "RC" },
    { value: "Driving license", label: "Driving license" },
    { value: "Passport", label: "Passport" },
  ];
  const checkKeyPress = useCallback(
    (e) => {
      const { key, keyCode } = e;
      if (keyCode === 13) {
        add_document();
      }
    },
    [d_data]
  );

  useEffect(() => {
    window.addEventListener("keydown", checkKeyPress);
    return () => {
      window.removeEventListener("keydown", checkKeyPress);
    };
  }, [checkKeyPress]);
  return (
    <Container maxW={"container.xl"} w={"full"} mt={5}>
      <HStack display={"flex"} justifyContent={"space-between"}>
        <BreadCrumName
          current={"Edit Document"}
          titlelist={[{ name: "Home", to: "/" },{ name: "Document", to: -1 }]}
        />
        <CustomButton1
          icon={<BiArrowBack />}
          title={"Back"}
          onClick={() => navigate(-1)}
        />
      </HStack>
      <Container
        w={"full"}
        maxW={"md"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Stack flexDirection={"column"} maxW={"md"} alignSelf={"center"} color={"#fff"}>
          <Text mb={0}>Select document type*</Text>
          <SelectBox
            value={options.filter((v) => v.value === d_data.type)}
            placeholder={"Search and select document"}
            onChange={(v) => setD_data({ ...d_data, type: v.value })}
            options={options}
          />

          {d_data?.type && (
            <Stack>
              <CustomInput
                placeholder={`Enter ${d_data.type} name`}
                label={`${d_data.type} name*`}
                value={d_data.name}
                onChange={(v) => setD_data({ ...d_data, name: v.target.value })}
              />

              <SelectImage
                label={"Upload file"}
                mt={"10px"}
                filename={d_data?.img?.name}
                type={d_data?.img?.type}
                url={d_data?.img?.name && path}
                onChange={(e) => {
                  setPath(window.URL.createObjectURL(e.target.files[0]));
                  setD_data({ ...d_data, img: e.target.files[0] });
                }}
              />
              {d_data.type == "Aadhar" || d_data.type == "PAN" || (
                <CustomInput
                  placeholder={"Enter Expiry Date"}
                  label={"Expiry Date*"}
                  type={"date"}
                  value={d_data.date}
                  min={"1900-01-01"}
                  max="9999-12-31"
                  onChange={(v) =>
                    setD_data({
                      ...d_data,
                      date: v.target.value,
                    })
                  }
                />
              )}
              <Center mt={4}>
                <CustomButton1
                  title={"Submit"}
                  onClick={add_document}
                  loading={loading}
                />
              </Center>
            </Stack>
          )}
        </Stack>
      </Container>
    </Container>
  );
};

export default EditDocument;
