import {
  Center,
  Container,
  Flex,
  Heading,
  Image,
  Text,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import BreadCrumName from "../components/BreadCrumName";
import { useLocation } from "react-router-dom";
import moment from "moment";
import store from "../redux/Store";
import { readNotification } from "../redux/slice/notificationSlice";

const NotificationDetail = () => {
  const location = useLocation();
  const loggedInUser = localStorage.getItem("user");
  const user = JSON.parse(loggedInUser);

  useEffect(() => {
    store.dispatch(
      readNotification({
        c_id: location.state.customerid,
        id: location.state.id,
      })
    );
  }, [location]);

  return (
    <Container maxW={"container.xl"} w={"full"} mt={5}>
      <BreadCrumName
        current={"Notification Detail"}
        titlelist={[
          { name: "Home", to: "/" },
          { name: "Notifications", to: "/all-notifications" },
        ]}
      />
      <Container
        w={"full"}
        maxW={"container.lg"}
        alignItems={"center"}
        justifyContent={"center"}
        mt={5}
        p={0}
      >
        <Flex justify={"space-between"}>
          <Heading fontSize={["sm", "md", "lg", "xl"]} mb={2} color={"#fff"}>
            {location?.state.title}
          </Heading>
          <Text
            mb={0}
            fontSize={["xs", "sm"]}
            fontWeight={"medium"}
            color={"#fff"}
          >
            {Date.parse(location.state.scheduledate)
              ? moment(location.state.scheduledate).fromNow()
              : moment(location.state.datetime).fromNow()}
          </Text>
        </Flex>

        {location?.state?.image?.length != 0 && (
          <Center>
            <Image
              src={location.state.image}
              w={"auto"}
              objectFit={"contain"}
              h={["150px", "250px", "300px", "sm"]}
              borderRadius={"md"}
              my={4}
            />
          </Center>
        )}
        <Text  color={"#fff"} fontSize={["sm", "md", "lg"]}>{location.state.message}</Text>
      </Container>
    </Container>
  );
};

export default NotificationDetail;
