import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";

function BreadCrumName({ titlelist = [], to, current }) {
  return (
    <Breadcrumb color={"#fff"} 
    fontWeight="extrabold">
      {titlelist.map((v, i) => (
        <BreadcrumbItem key={i}>
          <Link to={v.to}>
            <Text
              fontSize={["sm", "md", "lg"]}
              // fontWeight="extrabold"
              // color="#fff"
              mb={0}
            >
              {v.name}
            </Text>
          </Link>
        </BreadcrumbItem>
      ))}
      {current && (
        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink
            href={to}
            fontSize={["xs", "sm", "md", "lg"]}
          >
            {current}
          </BreadcrumbLink>
        </BreadcrumbItem>
      )}
    </Breadcrumb>
  );
}

export default BreadCrumName;
