import { Center, Container, Radio, RadioGroup, Stack } from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import BreadCrumName from "../../components/BreadCrumName";
import CustomInput from "../../components/CustomInput";
import CustomButton1 from "../../components/CustomButton1";
import { useSelector } from "react-redux";
import { addQute } from "../../utils/apis";
import {  emailRegex, infoMess } from "../../utils/utils";
import { useLocation, useNavigate } from "react-router-dom";

const BuyPolicy = () => {
  const navigate = useNavigate();
  const { user_data } = useSelector((state) => state.user);
  const [ask_data, setAsk_data] = useState(user_data);
  const [tab, setTab] = useState("Health");
  const [loading, setLoading] = useState(false);
  const loggedInUser = localStorage.getItem("user");
  const user = JSON.parse(loggedInUser);
  const ID = user?.userid;

  const {state} = useLocation();

  useEffect(()=>{
    if(state){
      setTab(state.value)
    }
  },[state])

  const sendQuote = () => {
    if (
      !ask_data.Name?.trim() ||
      !ask_data.Email?.trim() ||
      !ask_data.mobile?.trim() ||
      !ask_data.mess?.trim()
    ) {
      infoMess("all fields are required");
      return;
    }
    if (!emailRegex.test(ask_data.Email?.trim())) {
      infoMess("Invalid email address");
      return;
    }
    if (ask_data.mobile.length < 10) {
      infoMess("Invalid mobile number");
      return;
    }
    const body = new FormData();
    body.append("userid", ID);
    body.append("instype", tab);
    body.append("name", ask_data.Name?.trim());
    body.append("email", ask_data.Email?.trim());
    body.append("contact", ask_data.mobile?.trim());
    body.append("message", ask_data.mess?.trim());
    body.append("policy", "Buy");
    addQute(body, setLoading).then((v) => {
      v.success && setAsk_data({ ...ask_data, mess: "" });
      v.success && navigate("/");
    });
  };
  useEffect(() => {
    setAsk_data(user_data);
  }, [user_data]);
  const checkKeyPress = useCallback(
    (e) => {
      const { key, keyCode } = e;
      if (keyCode === 13) {
        sendQuote();
      }
    },
    [ask_data]
  );

  useEffect(() => {
    window.addEventListener("keydown", checkKeyPress);
    return () => {
      window.removeEventListener("keydown", checkKeyPress);
    };
  }, [checkKeyPress]);
  return (
    <Container maxW={"container.xl"} w={"full"} mt={5}>
      <BreadCrumName
        current={"Buy Policy"}
        titlelist={[{ name: "Home", to: "/" }]}
      />
      <Container maxW={"container.md"}>
        <RadioGroup onChange={setTab} value={tab} color={"#fff"}>
          <Stack direction="row" spacing={5}>
            <Radio value="Health">Health</Radio>
            <Radio value="Motor">Motor</Radio>
            <Radio value="Life">Life</Radio>
            <Radio value="Others">Others</Radio>
          </Stack>
        </RadioGroup>
        <CustomInput
          label={"Full Name*"}
          value={ask_data.Name}
          onChange={(e) => setAsk_data({ ...ask_data, Name: e.target.value })}
          placeholder={"Enter Full Name"}
        />
        <CustomInput
          label={"Email Address*"}
          value={ask_data.Email}
          onChange={(e) => setAsk_data({ ...ask_data, Email: e.target.value })}
          placeholder={"Enter Email Address"}
        />
        <CustomInput
          label={"Phone Number*"}
          value={ask_data.mobile}
          onChange={(e) =>
            e.target.value.length < 11 &&
            setAsk_data({
              ...ask_data,
              mobile: e.target.value.replace(/([a-zA-Z ])/g, ""),
            })
          }
          placeholder={"Enter Mobile Number"}
        />
        <CustomInput
          label={"Additional Information*"}
          value={ask_data.mess}
          onChange={(e) => setAsk_data({ ...ask_data, mess: e.target.value })}
          placeholder={"Enter Additional Information"}
          area
        />
        <Center mt={4}>
          <CustomButton1
            title={"Submit"}
            loading={loading}
            onClick={sendQuote}
          />
        </Center>
      </Container>
    </Container>
  );
};

export default BuyPolicy;
