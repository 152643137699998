import React, { useEffect } from "react";
import {
  Button,
  Card,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Stack,
  Text,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import { IoMdNotificationsOutline } from "react-icons/io";
import { useSelector } from "react-redux";
import store from "../redux/Store";
import { getNotifications } from "../redux/slice/notificationSlice";
import { useNavigate } from "react-router-dom";
import Loader from "./Loader";

const NotificationHeader = () => {
  const loggedInUser = localStorage.getItem("user");
  const user = JSON.parse(loggedInUser);
  const [isSmallThan375] = useMediaQuery("(max-width: 375px)");
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { notification_list, loading, unread_count } = useSelector(
    (state) => state.notifications
  );

  useEffect(() => {
    localStorage.getItem("user");
    // store.dispatch(getNotifications({id:user.userid,}));
  }, [isOpen]);

  return (
    <Popover placement="bottom-end" isOpen={isOpen} onClose={onClose}>
      <PopoverTrigger>
        <Button
          borderRadius={"full"}
          bg={"transparent"}
          px={0}
          _hover={{ bg: "transparent" }}
          onClick={onOpen}
          outline={"none"}
          border={"none"}
        >
          <IoMdNotificationsOutline
            size={25}
            style={{
              backgroundColor: "#fff",
              height: isSmallThan375 ? 20 : 30,
              width: isSmallThan375 ? 20 : 30,
              padding: 4,
              borderRadius: 20,
            }}
          />
          {unread_count == 0 || (
            <Stack
              h={6}
              w={6}
              borderRadius={20}
              bg={"#e0a604"}
              position={"absolute"}
              top={-1}
              right={-1}
              boxShadow={"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Text fontSize={10} fontWeight={600} color={"#fff"}>
                {unread_count > 99 ? "99+" : unread_count}
              </Text>
            </Stack>
          )}
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent maxW={["220px", "350px", "md", "xl"]}>
          <PopoverArrow />
          <PopoverHeader>Notifications</PopoverHeader>
          <PopoverCloseButton />
          <PopoverBody onClick={() => onClose()}>
            {notification_list.length != 0 && loading ? (
              <Loader />
            ) : notification_list.length == 0 ? (
              <Text textAlign={"center"} color={"#000"} fontSize={"md"}>
                No Notification available
              </Text>
            ) : (
              notification_list.slice(0, 5).map((v, i) => (
                <Card
                  key={i}
                  mb={2}
                  py={2}
                  px={3}
                  cursor={"pointer"}
                  onClick={() => navigate("/notification-detail", { state: v })}
                >
                  <Text
                    mb={0}
                    fontSize={["sm", "md"]}
                    fontWeight={Date.parse(v.viewdate) ? "normal" : "semibold"}
                    color={"#000"}
                  >
                    {v.title}
                  </Text>

                  <Text
                    mb={0}
                    fontSize={["xs", "sm"]}
                    fontWeight={"normal"}
                    color={"#000"}
                    noOfLines={1}
                  >
                    {v.message}
                  </Text>
                </Card>
              ))
            )}
            {notification_list.length == 0 || (
              <Text
                cursor={"pointer"}
                textAlign={"right"}
                mb={0}
                my={2}
                fontSize={["sm", "md"]}
                fontWeight={"semibold"}
                color={"#e0a604"}
                onClick={() => {
                  navigate("/all-notifications");
                }}
              >
                See More
              </Text>
            )}
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

export default NotificationHeader;
