import { Button, Image, Stack, Text, useMediaQuery } from "@chakra-ui/react";
import React from "react";
import { IoMdNotificationsOutline } from "react-icons/io";
import { CiBellOn } from "react-icons/ci";
import { useSelector } from "react-redux";

export const ImgComp = ({ img, title, onClick }) => {
  return (
    <Stack
      padding={["10px", 2, 5]}
      //   border={"1px solid red"}
      m={["5px", 2, 5]}
      borderRadius={5}
      shadow={"xs"}
      backgroundColor={"#fff"}
      cursor={"pointer"}
      onClick={onClick}
    >
      <Image
        src={img}
        w={["40px", "70px", "100px", "140px"]}
        h={["40px", "70px", "100px", "140px"]}
        objectFit={"contain"}
      />
      <Text
        fontSize={["12px", "14px", "16px"]}
        textAlign={"center"}
        color={"#000"}
        fontWeight={"normal"}
        marginTop={2}
      >
        {title}
      </Text>
    </Stack>
  );
};

export const ImgCompHome = ({
  img,
  title,
  onClick,
  bg,
  color,
  borderColor,
  border,
  imgW,
  imgH,
  p,
  notification,
  h,
  w,
  br,
  count,
  reDirect,
  data_count,
}) => {
  const [isSmallThan375] = useMediaQuery("(max-width: 375px)");
  const [isSmallThan500] = useMediaQuery("(max-width: 500px)");

  return (
    <Stack
      padding={["5px","10px", 2, 4]}
      //   border={"1px solid red"}
      borderRadius={br}
      m={["5px", 2, 5]}
      border={border ? border : ""}
      borderColor={borderColor ? borderColor : ""}
      // borderRadius={5}
      shadow={"xs"}
      flexDirection={"column"}
      justifyContent={"flex-start"}
      alignItems={"center"}
      backgroundColor={bg ? bg : "#fff"}
      cursor={"pointer"}
      onClick={onClick}
      h={h}
      w={w}
      maxW={h ? h : ["110px"]}
      minW={w ? w : ["110px"]}
      position={"relative"}
    >
      <Image
      color={"#fff"}
        p={p ? p : ""}
        src={img}
        w={imgW ? imgW : ["40px", "70px", "100px", "140px"]}
        h={imgH ? imgH : ["40px", "70px", "100px", "140px"]}
        objectFit={"contain"}
      />
      <Text
        fontSize={["15px", "18px", "20px", "22px", "23px"]}
        textAlign={"center"}
        style={{ wordWrap: "break-word" }}
        wordBreak={"break-word"}
        color={color ? color : "#000"}
        fontWeight={"normal"}
        marginTop={2}
      >
        {title} {data_count > 0 ? `(${data_count})`:""}
      </Text>
      {notification && count > 0 && (
        <Stack
          onClick={(e) => {
            e.stopPropagation();
            reDirect();
          }}
          position="absolute"
          right={["-10px", "-13px"]}
          top={["-10px", "-13px"]}
        >
          {/* <Button
            px={0}
            bg={"#e0a604"}
            color={"#e0a604"}
            borderRadius={"full"}
            outline={"none"}
            border={"none"}
            minW={[ "40px"]}
            h={[ "40px"]}
          >
            <CiBellOn
              size={isSmallThan500 ? 20 : 25}
              style={{
                color: "#fff",
                height: isSmallThan500 ? 20 : 39,
                width: isSmallThan500 ? 20 : 39,
                padding: isSmallThan500 ? 0 : 4,
                borderRadius: "100%",
              }}
            /> */}
          {count == 0 || (
            <Stack
              h={12}
              w={12}
              borderRadius={"50%"}
              bg={"#e0a604"}
              position={"absolute"}
              top={0}
              right={0}
              boxShadow={"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"}
              alignItems={"center"}
              justifyContent={"center"}
              // onClick={()=>}
            >
              <Text fontSize={16} fontWeight={600} color={"#fff"}>
                {count > 99 ? "99+" : count}
              </Text>
            </Stack>
          )}
          {/* </Button> */}
        </Stack>
      )}
    </Stack>
  );
};

// export default ImgComp;
