import { Card, Center, Container, Image, Text, VStack } from "@chakra-ui/react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import CustomInput from "../components/CustomInput";
import { Link, Navigate, useNavigate } from "react-router-dom";
import CustomButton1 from "../components/CustomButton1";
import { encryptData, passwordRegex, ID } from "../utils/utils";
import { handle_login, send_token } from "../utils/apis";
import store from "../redux/Store";
import { getPolicy_list } from "../redux/slice/policySlice";
import { getDocument_list } from "../redux/slice/DocumentSlice";
import { getUserData, setUser_id } from "../redux/slice/userSlice";
import { getNotifications } from "../redux/slice/notificationSlice";
import { getClaims } from "../redux/slice/claimSlice";

const Login = () => {
  const inputRef = useRef();
  const loggedInUser = localStorage.getItem("user");
  const user = JSON.parse(loggedInUser);
  const navigate = useNavigate();
  const [id, setId] = useState({ value: "", validate: false, mess: "" });
  const [password, setPassword] = useState({
    value: "",
    validate: false,
    mess: "",
  });
  const [loading, setLoading] = useState(false);
  const [show, setShow] = React.useState(false);

  const validate = () => {
    if (id.value.trim().length > 3) {
      if (passwordRegex.test(password.value.trim())) {
        const body = new FormData();
        body.append("contact", id.value.trim());
        body.append("password", encryptData(password.value.trim()));
        body.append("platform", "Web");
        handle_login(body, setLoading).then(async (v) => {
          if (v?.success) {
            await send_token(v.userid, localStorage.getItem('fbToken'));
            localStorage.setItem("popup", "show");
            localStorage.setItem("user", JSON.stringify(v));
            store.dispatch(setUser_id(v.userid));
            // store.dispatch(getUserData(v.userid));
            store.dispatch(getPolicy_list(v.userid));
            store.dispatch(getDocument_list(v.userid));
            // store.dispatch(getNotifications({id:v.userid,}));
            store.dispatch(getClaims(v.userid));
            navigate("/");
          }
        });
      } else {
        setPassword({
          ...password,
          validate: true,
          mess: "Password must be at least 8 characters long and contain at least  one special character !",
        });
      }
    } else {
      if (!passwordRegex.test(password.value.trim())) {
        setPassword({
          ...password,
          validate: true,
          mess: "Password must be at least 8 characters long and contain at least  one special character !",
        });
      }
      setId({ ...id, validate: true, mess: "Invalid Mobile no./Email ID!" });
    }
  };
  useEffect(() => {
    if (user !== null) {
      <Navigate to="/" replace />;
      return;
    }
  }, []);

  const checkKeyPress = useCallback(
    (e) => {
      const { key, keyCode } = e;

      if (keyCode === 13) {
        validate();
      }
    },
    [id, password]
  );

  useEffect(() => {
    window.addEventListener("keydown", checkKeyPress);
    return () => {
      window.removeEventListener("keydown", checkKeyPress);
    };
  }, [checkKeyPress]);
  return (
    <Container
      w={"full"}
      h={"full"}
      minH={"100vh"}
      maxW={"full"}
      bg={"#084480"}
      justifyContent={"center"}
      alignItems={"center"}
      display={["flex", "flex", "flex", "flex"]}
      background={"url(bg.png) center/cover no-repeat"}
    >
      <VStack
        h={"full"}
        w={"50%"}
        justify={"center"}
        align={"center"}
        display={["none", "none", "flex", "flex"]}
      ></VStack>
      <VStack
        h={"full"}
        w={["full", "70%", "50%"]}
        justify={"center"}
        align={"center"}
      >
        <Card
          px={5}
          size={"lg"}
          w={"full"}
          maxW={"md"}
          justify={"center"}
          py={5}
        >
          <Center>
            <Image
              src="logo.jpeg"
              // src="Logo2.png"
              // h={"60px"}
              w={"120px"}
              // bg={"gray"}
              borderRadius={5}
            />
          </Center>
          <Text fontSize={"lg"} textAlign={"center"} mt={2}>
            Login
          </Text>

          <CustomInput
            placeholder="Mobile no./Email ID"
            value={id.value}
            onChange={(e) => {
              e.preventDefault();
              setId({
                ...id,
                value: e.target.value,
                mess: "",
                validate: false,
              });
            }}
            isInvalid={id.validate}
            error={id.mess}
          />

          <CustomInput
            initialRef={inputRef}
            placeholder="Password"
            value={password.value}
            onChange={(e) => {
              e.preventDefault();
              setPassword({
                ...password,
                value: e.target.value,
                mess: "",
                validate: false,
              });
            }}
            isInvalid={password.validate}
            error={password.mess}
            type={show ? "text" : "password"}
            // right={
            //   !show ? (
            //     <FiEye cursor={"pointer"} size={18} onClick={handleClick} />
            //   ) : (
            //     <FiEyeOff cursor={"pointer"} size={18} onClick={handleClick} />
            //   )
            // }
          />
          <Text
            fontSize={["sm", "md"]}
            textAlign={"end"}
            mt={2}
            cursor={"pointer"}
            alignSelf={"end"}
            onClick={() => navigate("/reset-password")}
          >
            Forgot Password?
          </Text>
          <Center mt={4}>
            <CustomButton1
              id={"#submitButton"}
              title={"Login"}
              onClick={() => validate()}
              loading={loading}
            />
          </Center>
          <Link
            to={"/singup"}
            style={{
              textAlign: "center",
              marginTop: 10,
              fontSize: "16px",
              color: "#e0a604",
              alignSelf: "center",
            }}
          >
            Create Account?
          </Link>
        </Card>
      </VStack>
    </Container>
  );
};

export default Login;
