import { Card, Center, Spinner } from "@chakra-ui/react";
import React from "react";

const Loader = ({bg}) => {
  return (
    <Center py={"10"} bg={bg?bg:"gray.100"} mt={4} borderRadius={7}>
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="#e0a604"
        size="xl"
      />
    </Center>
  );
};

export default Loader;
