import { Flex, Spacer, Stack, VStack } from "@chakra-ui/react";
import React from "react";
import Header from "./Header";
import { Outlet, useLocation } from "react-router-dom";
import Footer from "./Footer";
import { useSelector } from "react-redux";

const Layout = () => {
  var {pathname} = useLocation()
  const { background_img } = useSelector((state) => state.other);


  return (
    <VStack
      h={"100vh"}
      // minH={"100vh"}
      maxW={"full"}
      spacing={0}
      // minW={"100wh"}
      // overflowY={"auto"}
    >
      <Stack
        h={["16","16","24"]}
        position={"sticky"}
        bg={"#727b82"}
        w={"full"}
        justify={"center"}
        // className="home_bg"
      >
        <Header />
      </Stack>
      <Stack
        overflowY={"auto"}
        w={"full"}
        // bg={pathname = '/'?"":"#fff"}
        h={"full"}
        maxW={"full"}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"space-between"}
        // className={pathname = '/' ?"home_bg":""}
        style={{"--bgimg" : `url(${background_img})`}}
        className="home_bg"
      >
        <Outlet />
        <Footer />
      </Stack>
    </VStack>
  );
};

export default Layout;
