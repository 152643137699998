import React, { useEffect, useRef, useState } from "react";
import { Flex, Heading, Stack, Text } from "@chakra-ui/react";
import Select from "react-select";

import { SlGlobe } from "react-icons/sl";
import { FaCaretDown } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const [onHover, setOnHover] = useState(false);
  const navigate = useNavigate();
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOnHover(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const options = [
    { value: "Motor", label: "Motor insurance" },
    { value: "Health", label: "Health insurance" },
    { value: "Life", label: "Life insurance" },
    { value: "Others", label: "Others" },
  ];
  return (
    <Flex
      bg={"#727b82"}
      maxW={"full"}
      direction={["row", "row"]}
      h={["120", "12", "24"]}
      minH={["100", "12", "24"]}
      align={"center"}
      px={["3px", "20"]}
      // gap={[4,1]}
      justify={"space-between"}
      color={"#fff"}
      fontSize={[15, 15, 18, 20]}
    >
      <Flex align={"flex-start"} direction="column">
        <Text
        >
          Get a <span style={{ color: "#FFD700",cursor:'pointer',fontWeight:"bold" }} 
           onClick={() => {
            navigate("/buy-policy");
          }}>Quote</span>
        </Text>
        <Text display={"flex"} gap={2} alignItems={"center"}>
          <SlGlobe color="#FFD700" /> info@insugo.in
        </Text>
      </Flex>
      <Flex align={"center"} direction="column">
        <div
          ref={dropdownRef}
          className="dropdown-ft"
          onClick={() => setOnHover(!onHover)}
          onMouseLeave={() => setOnHover(false)}
        >
          <button>Insurance type</button>
          <FaCaretDown
            className={onHover ? "down_arr" : "down_arr_2"}
            color="#fff"
          />
          {onHover && (
            <div className="dropdown-ft-content">
              {options.map((v, i) => (
                <div
                  key={i}
                  onClick={() => {
                    navigate("/buy-policy", {
                      state: v,
                    });
                    setOnHover(false);
                  }}
                >
                  {v?.label}
                </div>
              ))}
            </div>
          )}
        </div>
        <Heading fontSize={["sm", "md", "lg", "xl"]} color={"#fff"} mb={0}>
          © 2023 Insugo
        </Heading>
      </Flex>
    </Flex>
  );
};

export default Footer;
