import { Button } from "@chakra-ui/react";
import React from "react";

const CustomButton1 = ({
  title,
  loading,
  onClick,
  mt,
  bg,
  h,
  icon,
  id,
  t_color,
  disabled = false,
}) => {
  const buttonBg = disabled ? "gray" : bg || "#e0a604";

  return (
    <Button
      type="button"
      id={id}
      variant={"solid"}
      h={h || ["8", 30]}
      mt={mt}
      bg={buttonBg}
      borderWidth={1}
      _hover={{ bg: `${buttonBg}+77` || "#e0a60499" }}
      color={t_color || "#fff"}
      isLoading={loading}
      onClick={() => {
        if (!disabled) {
          onClick();
        }
      }}
      alignSelf={"flex-start"}
      gap={2}
      fontSize={["sm", "sm", "md"]}
      disabled={disabled}
    >
      {icon}
      {title}
    </Button>
  );
};


export default CustomButton1;
