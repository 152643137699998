import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { base_url } from "../../utils/apis";

const loggedInUser = localStorage.getItem("user");
const user = JSON.parse(loggedInUser);

export const getDocument_list = createAsyncThunk("documents", async (id) => {
  try {
    const body = new FormData();
    body.append("userid", id);
    body.append("action", "list");
    const responce = await fetch(base_url + "document.php", {
      method: "POST",
      body: body,
    });
    const res = await responce.json();

    return res;
  } catch (error) {}
});

const documentSlice = createSlice({
  name: "documents",
  initialState: {
    document_list: [],
    d_loading: false,
  },
  extraReducers: (builder) => {
    builder.addCase(getDocument_list.pending, (state, action) => {
      state.d_loading = true;
    });
    builder.addCase(getDocument_list.rejected, (state, action) => {
      state.d_loading = false;
    });
    builder.addCase(getDocument_list.fulfilled, (state, action) => {
      state.d_loading = false;
      const data = action.payload;
      if (data.success) {
        state.document_list = data.message;
      } else {
        state.document_list = [];
      }
    });
  },
});
export default documentSlice.reducer;
