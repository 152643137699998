import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { base_url } from "../../utils/apis";
// import { ID } from "../../utils/utils";

const loggedInUser = localStorage.getItem("user");
const user = JSON.parse(loggedInUser);
const ID = user?.userid;


export const getClaims = createAsyncThunk("claim", async (id) => {
  try {
    const body = new FormData();
    body.append("userid", user?.userid);
    body.append("action", "list");
    const responce = await fetch(base_url + "claims.php", {
      method: "POST",
      body: body,
    });
    const res = await responce.json();
    return res;
  } catch (error) {}
});

const claimSlice = createSlice({
  name: "claim",
  initialState: {
    claim_data: [],
    loading: false,
  },
  extraReducers: (builder) => {
    builder.addCase(getClaims.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getClaims.fulfilled, (state, action) => {
      state.loading = false;
      const data = action.payload;
      if (data.success) {
        state.claim_data = data.message;
      } else {
        state.claim_data = [];
      }
    });
  },
});
export default claimSlice.reducer;
