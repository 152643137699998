import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { base_url } from "../../utils/apis";

const loggedInUser = localStorage.getItem("user");
const user = JSON.parse(loggedInUser);

export const getPolicy_list = createAsyncThunk("policy", async (id) => {
  try {
    const body = new FormData();
    body.append("userid", id);
    body.append("action", "list");
    const responce = await fetch(base_url + "policy.php", {
      method: "POST",
      body: body,
    });
    const res = await responce.json();

    return res;
  } catch (error) {
    return {
      success: false,
    };
  }
});

export const searchPolicy_list = createAsyncThunk(
  "search-policy",
  async (data) => {
    try {
      const body = new FormData();
      body.append("userid", data.id);
      body.append("name", data.type);
      body.append("action", "filter");
      const responce = await fetch(base_url + "policy.php", {
        method: "POST",
        body: body,
      });
      const res = await responce.json();

      return res;
    } catch (error) {}
  }
);

export const getPolicy_provider_list = createAsyncThunk(
  "provider",
  async () => {
    try {
      const body = new FormData();
      body.append("action", "list");
      const responce = await fetch(base_url + "provider.php", {
        method: "POST",
        body: body,
      });
      const res = await responce.json();

      return res;
    } catch (error) {}
  }
);
export const getOther_Policy_list = createAsyncThunk(
  "policies-list",
  async () => {
    try {
      const body = new FormData();
      body.append("action", "list");
      const responce = await fetch(base_url + "policy-list.php", {
        method: "POST",
        body: body,
      });
      const res = await responce.json();

      return res;
    } catch (error) {}
  }
);

const policySlice = createSlice({
  name: "policy",
  initialState: {
    policy_list: [],
    search_policy_list: [],
    policy_provider_list: [],
    other_policy_list: [],
    provider_loading: false,
    other_loading: false,
    p_loading: false,
    s_loading: false,
  },
  extraReducers: (builder) => {
    builder.addCase(getPolicy_list.pending, (state, action) => {
      state.p_loading = true;
    });
    builder.addCase(getPolicy_list.rejected, (state, action) => {
      state.p_loading = false;
    });
    builder.addCase(getPolicy_list.fulfilled, (state, action) => {
      state.p_loading = false;
      const data = action.payload;
      if (data.success) {
        state.policy_list = data.message;
      } else {
        state.policy_list = [];
      }
    });

    builder.addCase(searchPolicy_list.pending, (state, action) => {
      state.s_loading = true;
    });
    builder.addCase(searchPolicy_list.rejected, (state, action) => {
      state.s_loading = false;
    });
    builder.addCase(searchPolicy_list.fulfilled, (state, action) => {
      state.s_loading = false;
      const data = action.payload;
      if (data.success) {
        state.search_policy_list = data.message;
      } else {
        state.search_policy_list = [];
      }
    });

    builder.addCase(getPolicy_provider_list.pending, (state, action) => {
      state.provider_loading = true;
    });
    builder.addCase(getPolicy_provider_list.rejected, (state, action) => {
      state.provider_loading = false;
    });
    builder.addCase(getPolicy_provider_list.fulfilled, (state, action) => {
      state.provider_loading = false;
      const data = action.payload;
      if (data.success) {
        state.policy_provider_list = data.message;
      } else {
        state.policy_provider_list = [];
      }
    });

    builder.addCase(getOther_Policy_list.pending, (state, action) => {
      state.other_loading = true;
    });
    builder.addCase(getOther_Policy_list.rejected, (state, action) => {
      state.other_loading = false;
    });
    builder.addCase(getOther_Policy_list.fulfilled, (state, action) => {
      state.other_loading = false;
      const data = action.payload;
      if (data.success) {
        state.other_policy_list = data.message;
      } else {
        state.other_policy_list = [];
      }
    });
  },
});
export default policySlice.reducer;
