import {
  AspectRatio,
  Container,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { decryptData, downloadImage } from "../utils/utils";
import CustomButton1 from "./CustomButton1";
import { FiDownload } from "react-icons/fi";
const ImageModal = ({ url = "", isOpen, onClose }) => {
  const [loading, setLoading] = useState(false);

  const [playVid, setPlayVid] = useState(false)

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
        url = "";
      }}
      isCentered={true}
    >
      <ModalOverlay />
      <ModalContent
        maxW={"container.xl"}
        maxH={"95%"}
        h={"auto"}
        w={"full"}
        padding={0}
        margin={1}
        // position={"fixed"}
      >
        <ModalCloseButton zIndex={100} bg={"#99999988"} />
        {url?.split(".").pop().trim() == "pdf" || (
          <ModalHeader>
            <Flex justify={"end"} marginRight={10}>
              <CustomButton1
                title={"Download"}
                onClick={() => downloadImage(url)}
                icon={<FiDownload />}
              />
            </Flex>
          </ModalHeader>
        )}
        <ModalBody w={"full"} h={"full"} position={"relative"} overflow={"scroll"}>
          {url?.split(".").pop().trim() == "mp4" ? (
          
            <video
            style={{
              width: "100%",
              height: "auto",
              maxHeight: "430px",
              // maxHeight:"80%",
              cursor:  "pointer",
            }}
            autoPlay={false}
            controls
            // loop
            muted={false}
            // className="background-video"
          >
            <source src={url} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          ) : (
            <>
              {url?.split(".").pop().trim() == "pdf" ? (
                <iframe
                  src={url}
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                  frameborder="1"
                  loading="eager"
                  onLoadStart={() => setLoading(true)}
                  onLoadedData={() => setLoading(false)}
                ></iframe>
              ) : (
                // <Container h={"full"}>

                <Image
                  src={url}
                  w={"full"}
                  h={"full"}
                  // minH={"max-content"}
                  objectFit={"contain"}
                />

                // </Container>
              )}
            </>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ImageModal;
