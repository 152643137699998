import {
  Center,
  Container,
  HStack,
  Select as Selectchakra,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import BreadCrumName from "../../components/BreadCrumName";
import CustomInput from "../../components/CustomInput";
import CustomButton1 from "../../components/CustomButton1";
import SelectImage from "../../components/SelectImage";
import {  infoMess } from "../../utils/utils";
import { useLocation, useNavigate } from "react-router-dom";
import { addUserPolicy } from "../../utils/apis";
import moment from "moment";
import Select from "react-select";
import store from "../../redux/Store";
import {
  getOther_Policy_list,
  getPolicy_provider_list,
} from "../../redux/slice/policySlice";
import { useSelector } from "react-redux";
import { BiArrowBack } from "react-icons/bi";
import SelectBox from "../../components/SelectBox";

const Editpolicy = () => {
  const { user_id } = useSelector((state) => state.user);
  const { policy_provider_list, other_policy_list } = useSelector(
    (state) => state.policy
  );
// Changes by NP
  const loggedInUser = localStorage.getItem("user");
  const user = JSON.parse(loggedInUser);
  const ID = user?.userid;


  const navigate = useNavigate();
  const location = useLocation();
  const [p_data, setP_data] = useState({});
  const [loading, setLoading] = useState(false);
  const [pdf, setPdf] = useState("");
  const add_policy = () => {
    if (
      !p_data.name?.trim() ||
      !p_data.company ||
      !p_data.plan?.trim() ||
      !p_data.p_no?.trim() ||
      !p_data.date?.trim()
    ) {
      infoMess("Please fill all fields");
      return;
    }
    const char = p_data?.name?.trim();
    const firstChar = char?.charAt(0);
     
    if (/^[^a-zA-Z]+$/.test(firstChar)) {
      infoMess("Name could not contain special characters or spaces at the beginning");
      return;
    }
    const body = new FormData();
    body.append("action", "update");
    body.append("userid", ID);
    body.append("id", location.state.id?.trim());
    body.append("instype", p_data.type == "Other" ? p_data.other?.trim() : p_data.type?.trim());
    body.append("holname", p_data.name?.trim());
    body.append("polcompany", p_data?.company);
    body.append("polplan", p_data.plan?.trim());
    body.append("polnum", p_data.p_no?.trim());
    body.append("expiry", p_data.date?.trim());
    {
      p_data?.img && body.append("policyfile", p_data?.img);
    }
    addUserPolicy(body, setLoading).then((v) => {
      v.success && navigate("/policy", { replace: true });
    });
  };
  const upload_doc = async (file) => {
    try {
      var formdata = new FormData();
      formdata.append("document", file);
      formdata.append(
        "instype",
        p_data.type.value == "Other" ? p_data.other.value : p_data.type.value
      );
      formdata.append("polcompany", p_data.company.value);

      const response = await fetch("https://infinititek.us/upload", {
        method: "POST",
        body: formdata,
      });
      const res = await response.json();

      //
      if (res.success) {
        setP_data({
          ...p_data,
          date: res.data.expiry,
          p_no: res.data.polnum,
          name: res.data.username,
          img: file,
          plan: res.data.plan,
        });
      } else {
      }
    } catch (error) {}
  };

  useEffect(() => {
    setP_data({
      ...p_data,
      other:
        location.state.insurance_type === "Motor"
          ? ""
          : location.state.insurance_type === "Health"
          ? ""
          : location.state.insurance_type === "Life"
          ? ""
          : location.state.insurance_type,
      type:
        location.state.insurance_type === "Motor"
          ? location.state.insurance_type
          : location.state.insurance_type === "Health"
          ? location.state.insurance_type
          : location.state.insurance_type === "Life"
          ? location.state.insurance_type
          : "Other",
      company: location.state.policy_company,
      name: location.state.name,
      p_no: location.state.policy_number,
      plan: location.state.policy_plan,
      date: location.state.expiry_date,
      c_img: location.state.policy_file,
    });
  }, []);
  const pro_list = [];
  for (let i = 0; i < policy_provider_list.length; i++) {
    const element = {
      value: policy_provider_list[i].provider,
      label: policy_provider_list[i].provider,
    };
    pro_list.push(element);
  }
  const policies_list = [];
  for (let i = 0; i < other_policy_list.length; i++) {
    const element = {
      value: other_policy_list[i].policyname,
      label: other_policy_list[i].policyname,
    };
    policies_list.push(element);
  }
  const options = [
    { value: "Motor", label: "Motor insurance" },
    { value: "Health", label: "Health insurance" },
    { value: "Life", label: "Life insurance" },
    { value: "Other", label: "Other" },
  ];
  useEffect(() => {
    store.dispatch(getPolicy_provider_list());
    store.dispatch(getOther_Policy_list());
  }, []);
  const checkKeyPress = useCallback(
    (e) => {
      const { key, keyCode } = e;
      if (keyCode === 13) {
        add_policy();
      }
    },
    [p_data]
  );

  useEffect(() => {
    window.addEventListener("keydown", checkKeyPress);
    return () => {
      window.removeEventListener("keydown", checkKeyPress);
    };
  }, [checkKeyPress]);
  return (
    <Container maxW={"container.xl"} w={"full"} mt={5}>
      <HStack display={"flex"} justifyContent={"space-between"}>
        <BreadCrumName
          current={"Edit Policy"}
          titlelist={[{ name: "Home", to: "/" },{ name: "Policy", to: -1 }]}
        />
        <CustomButton1
          icon={<BiArrowBack />}
          title={"Back"}
          onClick={() => navigate(-1)}
        />
      </HStack>
      <Container
        w={"full"}
        maxW={"md"}
        alignItems={"center"}
        justifyContent={"center"}
        color={"#fff"}
      >
        <Stack flexDirection={"column"} maxW={"md"} alignSelf={"center"}>
          <Text mb={0}>Select policy type*</Text>
          <SelectBox
            value={options.filter((v) => v.value === p_data.type)}
            placeholder={"Search and select policy"}
            onChange={(v) => setP_data({ ...p_data, type: v.value })}
            options={options}
          />

          {p_data?.type && (
            <Stack pt={"4"}>
              {(p_data?.type.value == "Other" || p_data?.type == "Other") && (
                <>
                  <Text mb={0}>Select other policy*</Text>
                  <SelectBox
                    value={policies_list.filter(
                      (v) => v.value === p_data.other
                    )}
                    placeholder={"Search and select other policy"}
                    onChange={(v) => setP_data({ ...p_data, other: v.value })}
                    options={policies_list}
                  />
                </>
              )}
              <Text mb={0}>Policy company*</Text>
              <SelectBox
                placeholder={"Search and select policy company"}
                value={pro_list.filter((v) => v.value === p_data.company)}
                onChange={(v) => setP_data({ ...p_data, company: v.value })}
                options={pro_list}
              />
              <SelectImage
                doctype={".pdf,.png,.jpeg,.jpg"}
                label={"Upload policy copy"}
                mt={"10px"}
                filename={p_data?.img?.name}
                url={p_data?.img?.name && pdf}
                type={p_data?.img?.type}
                onChange={(e) => {
                  setPdf(window.URL.createObjectURL(e.target.files[0]));
                  setP_data({
                    ...p_data,
                    img: e.target.files[0],
                    name: "",
                    p_no: "",
                    plan: "",
                    date: "",
                  });
                  upload_doc(e.target.files[0]);
                }}
              />
              <CustomInput
                placeholder={"Enter Policy holder name"}
                label={"Policy holder name*"}
                value={p_data.name}
                onChange={(v) => setP_data({ ...p_data, name: v.target.value })}
              />
              <CustomInput
                placeholder={
                  p_data.type == "Motor"
                    ? "Enter Vehicel number"
                    : "Enter Policy Plan"
                }
                label={
                  p_data.type == "Motor" ? "Vehicel number*" : "Policy Plan*"
                }
                value={p_data.plan}
                onChange={(v) => setP_data({ ...p_data, plan: v.target.value })}
              />
              <CustomInput
                placeholder={"Enter Policy number"}
                label={"Policy number*"}
                value={p_data.p_no}
                onChange={(v) => setP_data({ ...p_data, p_no: v.target.value })}
              />
              <CustomInput
                placeholder={"Enter Expiry Date"}
                label={"Expiry Date*"}
                type={"date"}
                value={p_data.date}
                min={"1900-01-01"}
                max="9999-12-31"
                onChange={(v) =>
                  setP_data({
                    ...p_data,
                    date: v.target.value,
                  })
                }
              />
              <Center>
                <CustomButton1
                  title={"Submit"}
                  onClick={add_policy}
                  loading={loading}
                />
              </Center>
            </Stack>
          )}
        </Stack>
      </Container>
    </Container>
  );
};

export default Editpolicy;
