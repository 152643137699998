import { toast } from "react-toastify";
import { errorMess, successMess } from "./utils";
import store from "../redux/Store";
import { getUserData } from "../redux/slice/userSlice";
const loggedInUser = localStorage.getItem("user");
const user = JSON.parse(loggedInUser);

// export const base_url = "https://insugo.vensframe.com/api/";
export const base_url = "https://digi.insugo.in/api/";
// export const base_url = "https://digi.pmpframe.com/api/";
// export const base_url = "https://digi.insugo.in/api/";
// export const base_url = "https://security.insugo.in/api/";

export const handle_login = async (body, setLoading) => {
  try {
    setLoading(true);
    const responce = await fetch(base_url + "login.php", {
      method: "POST",
      body: body,
    });
    const res = await responce.json();
    setLoading(false);
    if (res.success) {
      // window.location.reload();
      // localStorage.setItem("popup", "show");
      successMess(res.message);
      store.dispatch(getUserData(res.userid));
      return res;
    } else {
      errorMess(res.message);
      return res;
    }
  } catch (error) {
    setLoading(false);
  }
};

export const send_otp = async (mobile, setLoading,type) => {
  try {
    setLoading(1);
    const body = new FormData();
    body.append("contact", mobile);
    body.append("type", type?type:"signup");

    const responce = await fetch(base_url + "otp-request.php", {
      method: "POST",
      body: body,
    });
    const res = await responce.json();
    setLoading("");

    return res;
  } catch (error) {
    setLoading("");
  }
};

export const register = async (body, setLoading) => {
  try {
    setLoading(5);

    const responce = await fetch(base_url + "signup.php", {
      method: "POST",
      body: body,
    });
    const res = await responce.json();
    setLoading("");

    if (res.success) {
      window.location.reload();
      successMess(res.message);
      return res;
    } else {
      errorMess(res.message);
      return res;
    }
  } catch (error) {
    setLoading("");
  }
};
export const addUserPolicy = async (body, setLoading) => {
  try {
    setLoading(true);
    const response = await fetch(base_url + "policy.php", {
      body: body,
      method: "POST",
    });
    const res = await response.json();

    setLoading(false);
    if (res.success) {
      successMess(res.message);
      return res;
    } else {
      errorMess(res.message);
      return res;
    }
  } catch (error) {
    setLoading(false);
  }
};

export const addUserDocument = async (body, setLoading) => {
  try {
    setLoading(true);
    const response = await fetch(base_url + "document.php", {
      body: body,
      method: "POST",
    });
    const res = await response.json();

    setLoading(false);
    if (res.success) {
      successMess(res.message);
      return res;
    } else {
      errorMess(res.message);
      return res;
    }
  } catch (error) {
    setLoading(false);
  }
};

export const addQute = async (body, setLoading) => {
  try {
    setLoading(true);
    const response = await fetch(base_url + "contact.php", {
      body: body,
      method: "POST",
    });
    const res = await response.json();

    setLoading(false);
    if (res.success) {
      successMess(res.message);
      return res;
    } else {
      errorMess(res.message);
      return res;
    }
  } catch (error) {
    setLoading(false);
  }
};

export const otp = async (mobile, mobile1, setLoading, load) => {
  try {
    setLoading(load);
    const body = new FormData();
    body.append("contact", mobile);
    body.append("mobile", mobile1);
    body.append("type", "mobileupdate");

    const responce = await fetch(base_url + "otp-request.php", {
      method: "POST",
      body: body,
    });
    const res = await responce.json();
    setLoading("");

    return res;
  } catch (error) {
    setLoading("");
  }
};

export const profile_action = async (body, setLoading, load) => {
  try {
    setLoading(load);
    const responce = await fetch(base_url + "profile.php", {
      method: "POST",
      body: body,
    });
    const res = await responce.json();
    setLoading("");

    return res;
  } catch (error) {
    setLoading("");
  }
};

export const ask_expert = async (body, setLoading, load) => {
  try {
    setLoading(load);
    const responce = await fetch(base_url + "contact.php", {
      method: "POST",
      body: body,
    });
    const res = await responce.json();
    setLoading("");

    return res;
  } catch (error) {
    setLoading("");
  }
};

export const forgot_pass = async (body, setLoading) => {
  try {
    setLoading("for");
    const responce = await fetch(base_url + "profile.php", {
      method: "POST",
      body: body,
    });
    const res = await responce.json();
    setLoading("");

    return res;
  } catch (error) {
    setLoading("");
  }
};

export const reset_otp = async (mobile, mobile1, setLoading) => {
  try {
    setLoading(2);
    const body = new FormData();
    body.append("contact", mobile);
    body.append("mobile", mobile1);
    body.append("type", "login");

    const responce = await fetch(base_url + "otp-request.php", {
      method: "POST",
      body: body,
    });
    const res = await responce.json();
    setLoading(false);

    return res;
  } catch (error) {
    setLoading(false);
  }
};

export const send_token = async (id, token) => {
  try {
    const body = new FormData();
    body.append("userid", id);
    body.append("medium", "web");
    body.append("token", token);

    const response = await fetch(base_url + "token.php", {
      body: body,
      method: "POST",
    });

    const res = await response.json();
    if (res.success) {
      return true;
    } else {
      toast.error(res.message);
      return false;
    }
  } catch (error) {}
};

export const upload_document = async (body) => {
  try {
    const response = await fetch("https://infinititek.us/upload", {
      body: body,
      method: "POST",
    });

    const res = await response.json();

    if (res.success) {
      return true;
    } else {
      toast.error(res.message);
      return false;
    }
  } catch (error) {}
};
export const delete_acc = async (id, setLoading, navigate) => {
  try {
    setLoading(true);
    const body = new FormData();
    body.append("action", "delete");
    body.append("id", id);
    body.append("status", 1);

    const response = await fetch(base_url + "profile.php", {
      body: body,
      method: "POST",
    });

    const res = await response.json();

    setLoading(false);
    if (res.success) {
      toast.success(res.message);
      localStorage.removeItem("user");
      localStorage.removeItem("fbToken");
      navigate("/login");
      return true;
    } else {
      toast.error(res.message);
      return false;
    }
  } catch (error) {
    setLoading(false);
  }
};

export const doc_status_action = async (body) => {
  try {
    const response = await fetch(base_url + "profile.php", {
      body: body,
      method: "POST",
    });

    const res = await response.json();

    return res;
  } catch (error) {}
};
export const doc_verify_action = async (body) => {
  try {
    const response = await fetch(base_url + "document.php", {
      body: body,
      method: "POST",
    });

    const res = await response.json();
    return res;
  } catch (error) {}
};

export const reset_doc_pass = async (body, setLoading) => {
  try {
    setLoading(true);
    const response = await fetch(base_url + "profile.php", {
      body: body,
      method: "POST",
    });
    const res = await response.json();

    setLoading(false);
    return res;
  } catch (error) {
    setLoading(false);
  }
};
export const status_check = async (id, navigate) => {
  try {
    const body = new FormData();
    body.append("userid", id);
    const response = await fetch(base_url + "logincheck.php", {
      body: body,
      method: "POST",
    });
    const res = await response.json();

    if (!res.success) {
      localStorage.removeItem("user");
      localStorage.removeItem("fbToken");
      navigate("/login");
    }
  } catch (error) {}
};

export const provider_action = async (type, setLoading) => {
  try {
    setLoading(true);
    const body = new FormData();
    body.append("action", "listbytype");
    body.append("instype", type);
    const response = await fetch(base_url + "provider.php", {
      body: body,
      method: "POST",
    });
    const res = await response.json();

    setLoading(false);
    return res;
  } catch (error) {
    setLoading(false);
  }
};
