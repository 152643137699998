// import { Select } from "react-select";
import Select from "react-select";
import React from "react";

const SelectBox = ({ value, onChange, options,placeholder }) => {
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "white" : "black", // color of the text
      backgroundColor: state.isSelected ? "#4797ed" : "white", // background color
      fontWeight: state.isSelected ? "bold" : "400",
    }),
  };
  return (
    <Select
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      options={options}
      styles={customStyles}
    />
  );
};

export default SelectBox;
