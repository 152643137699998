import {
  Card,
  Center,
  Container,
  HStack,
  Image,
  PinInput,
  PinInputField,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import CustomInput from "../components/CustomInput";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import CustomButton1 from "../components/CustomButton1";
import { encryptData, passwordRegex, ID } from "../utils/utils";
import {
  handle_login,
  profile_action,
  send_otp,
  send_token,
} from "../utils/apis";
import store from "../redux/Store";
import { getPolicy_list } from "../redux/slice/policySlice";
import { getDocument_list } from "../redux/slice/DocumentSlice";
import { getUserData, setUser_id } from "../redux/slice/userSlice";
import { getNotifications } from "../redux/slice/notificationSlice";
import { getClaims } from "../redux/slice/claimSlice";
import { toast } from "react-toastify";

const DeleteAccount = () => {
  const [show_otp, setShow_otp] = useState(false);
  const [otp, setOtp] = useState("");
  const [disBtn, setDisBtn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState("");
  const navigate = useNavigate();

  const [mobile, setMobile] = useState({
    value: "",
    validate: false,
    mess: "",
  });

  useEffect(() => {
    if (show_otp) {
      const timeoutId = setTimeout(() => {
        setDisBtn(false);
      }, 60000);
    }
  }, [show_otp]);

  const get_otp = () => {
    if (validateInput(mobile?.value) != true) {
      setMobile({
        ...mobile,
        validate: true,
        mess: validateInput(mobile?.value),
      });
      return;
    }

    send_otp(mobile.value, setLoading, "delete").then((v) => {
      if (v.success) {
        setId(v?.id);
        setShow_otp(true);
        setDisBtn(true);
        toast.success(v?.message);
        setOtp("")
      } else {
        toast.error(v?.message);
        setShow_otp(false);
      }
    });
  };

  const validate = async () => {
    if (validateInput(mobile?.value) != true) {
      setMobile({
        ...mobile,
        validate: true,
        mess: validateInput(mobile?.value),
      });
      return;
    }
    try {
      const body = new FormData();
      body.append("otp", otp);
      body.append("otpid", id);
      body.append("id", id);
      body.append("contact", mobile?.value);
      body.append("action", "deleteverify");
      body.append("status", 1);

      const res = await profile_action(body, setLoading, 5);
      if (res.success) {
        toast.success(res.message);
        navigate("/login");
      } else {
        toast.error(res.message);
        setDisBtn(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  function validateInput(data) {
    const value = data.trim();

    // Regular expressions for validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const mobileRegex = /^[0-9]{10,15}$/; // Assuming mobile number length between 10 and 15 digits

    // Check if the value meets the minimum length requirement
    if (!value) {
      return "Mobile No./Email required";
    }

    // Check if the value matches either the email or mobile number pattern
    if (!emailRegex.test(value) && !mobileRegex.test(value)) {
      return "Please enter a valid Mobile No./Email.";
    }

    // If the input is valid
    return true;
  }
  return (
    <Container
      w={"full"}
      h={"full"}
      minH={"100vh"}
      maxW={"full"}
      bg={"#084480"}
      justifyContent={"center"}
      alignItems={"center"}
      display={["flex", "flex", "flex", "flex"]}
      background={"url(/bg.png) center/cover no-repeat"}
    >
      <VStack
        h={"full"}
        w={"50%"}
        justify={"center"}
        align={"center"}
        display={["none", "none", "flex", "flex"]}
      ></VStack>
      <VStack
        h={"full"}
        w={["full", "70%", "50%"]}
        justify={"center"}
        align={"center"}
      >
        <Card
          px={5}
          size={"lg"}
          w={"full"}
          maxW={"md"}
          justify={"center"}
          py={5}
        >
          <Center>
            <Image
              src="/logo.jpeg"
              // src="Logo2.png"
              // h={"60px"}
              w={"120px"}
              // bg={"gray"}
              borderRadius={5}
            />
          </Center>
          <Text fontSize={"lg"} textAlign={"center"} mt={2}>
            Delete Account
          </Text>

          <CustomInput
            placeholder="Mobile no./Email ID"
            value={mobile.value}
            disabled={show_otp}
            onChange={(e) => {
              setMobile({
                ...mobile,
                value: e.target.value,
                validate: false,
                mess: "",
              });
            }}
            isInvalid={mobile.validate}
            error={mobile.mess}
            rightButton={
              <Center mt={1}>
                <CustomButton1
                  title={show_otp ? "Resend OTP" : "Get OTP"}
                  loading={loading == 1 ? true : false}
                  onClick={() => get_otp()}
                  disabled={disBtn}
                />
              </Center>
            }
          />

          {show_otp && (
            <HStack justify={"center"} mt={3}>
              <PinInput placeholder="-" autoFocus value={otp} onChange={setOtp}>
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
              </PinInput>
            </HStack>
          )}

          <Center mt={4}>
            <CustomButton1
              id={"#submitButton"}
              title={"Delete"}
              disabled={otp?.length <= 5}
              onClick={() => validate()}
              loading={loading == 5}
            />
          </Center>

          {/* <CustomInput
            placeholder="Mobile no./Email ID"
            value={id.value}
            onChange={(e) => {
              e.preventDefault();
              setId({
                ...id,
                value: e.target.value,
                mess: "",
                validate: false,
              });
            }}
            isInvalid={id.validate}
            error={id.mess}
          />

          <CustomInput
            initialRef={inputRef}
            placeholder="Password"
            value={password.value}
            onChange={(e) => {
              e.preventDefault();
              setPassword({
                ...password,
                value: e.target.value,
                mess: "",
                validate: false,
              });
            }}
            isInvalid={password.validate}
            error={password.mess}
            type={show ? "text" : "password"}
          /> */}
          {/* <Text
            fontSize={["sm", "md"]}
            textAlign={"end"}
            mt={2}
            cursor={"pointer"}
            alignSelf={"end"}
            onClick={() => navigate("/reset-password")}
          >
            Forgot Password?
          </Text>
          <Center mt={4}>
            <CustomButton1
              id={"#submitButton"}
              title={"Login"}
              onClick={() => validate()}
              loading={loading}
            />
          </Center>
          <Link
            to={"/singup"}
            style={{
              textAlign: "center",
              marginTop: 10,
              fontSize: "16px",
              color: "#e0a604",
              alignSelf: "center",
            }}
          >
            Create Account?
          </Link> */}
        </Card>
      </VStack>
    </Container>
  );
};

export default DeleteAccount;
