import React, { useEffect, useState } from "react";

import BreadCrumName from "../../components/BreadCrumName";
import {
  Box,
  Card,
  Center,
  Container,
  Flex,
  Image,
  Link,
  SimpleGrid,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import store from "../../redux/Store";
import { getClaims } from "../../redux/slice/claimSlice";
import { FiPhoneCall } from "react-icons/fi";
import { BsFillCarFrontFill } from "react-icons/bs";
import { MdEmail, MdHealthAndSafety } from "react-icons/md";
import ImageModal from "../../components/ImageModal";
import { CopyIcon } from "@chakra-ui/icons";
import { successMess } from "../../utils/utils";

const Claims = () => {
  const loggedInUser = localStorage.getItem("user");
  const user = JSON.parse(loggedInUser);
  const { claim_data } = useSelector((state) => state.claims);

  const [url, setUrl] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  useEffect(() => {
    store.dispatch(getClaims(user.userid));
  }, []);

  async function copyToClip(v) {
    await navigator.clipboard.writeText(v);
    successMess("Copied!");
  }
  return (
    <Container maxW={"container.xl"} w={"full"} mt={5}>
      <BreadCrumName
        current={"Claims"}
        titlelist={[{ name: "Home", to: "/" }]}
      />
      <Text
        textAlign={"center"}
        fontSize={["md", "lg", "xl", "2xl"]}
        fontWeight={"medium"}
        color={"#fff"}
      >
        Insurance Companies - Claim
      </Text>
      {claim_data.length == 0 ? (
        <Center>
          <Image src={"no-data1.png"} h={"sm"} w={"sm"} objectFit={"contain"} />
        </Center>
      ) : (
        <SimpleGrid columns={[1, 2, 3, 4]} spacing={2} mt={3} justifyContent={"center"}>
          {claim_data.map((v, i) => (
            <Card key={i} maxW={"xs"} p={5} minW={"full"}>
              <Center mb={2}>
                <Image
                  src={v.image}
                  w={"7rem"}
                  h={"3rem"}
                  bg={"gray.50"}
                  objectFit={"contain"}
                />
              </Center>

              <CustomLink
                icon={<FiPhoneCall size={20} color="green" />}
                title={v.contact}
                href={`tel:${v.contact}`}
              />

              {v.email?.length == 0 || (
                <CustomLink
                  icon={<MdEmail size={20} color="green" />}
                  title={v.email}
                  href={`mailto:${v.email}`}
                  right={
                    <Box
                      onClick={() => {
                        copyToClip(v.email);
                      }}
                      display={"flex"}
                    >
                      <CopyIcon h={5} w={5} />
                    </Box>
                  }
                />
              )}

              <Flex alignItems={"center"} gap={2} justify={"space-between"}>
                {(v.healthurl?.length == 0 && v.health == 0) || (
                  <CustomLink
                    icon={<MdHealthAndSafety size={20} color="green" />}
                    title={v.health.length != 0 ? "Health claim" : ""}
                    onClick={() => {
                      setUrl(v.health);
                      onOpen();
                    }}
                  />
                )}

                {v.healthurl?.length == 0 || (
                  <Link href={v.healthurl} isExternal mt={2}>
                    <Text
                      fontSize={"sm"}
                      fontWeight={"medium"}
                      cursor={"pointer"}
                    >
                      Apply Online
                    </Text>
                  </Link>
                )}
              </Flex>
              <Flex alignItems={"center"} gap={2} justify={"space-between"}>
                {(v.motorurl?.length == 0 && v.motor == 0) || (
                  <CustomLink
                    icon={<BsFillCarFrontFill size={20} color="green" />}
                    title={v.motor != 0 ? "Motor claim" : ""}
                    onClick={() => {
                      setUrl(v.motor);
                      onOpen();
                    }}
                  />
                )}

                {v.motorurl?.length == 0 || (
                  <Link href={v.motorurl} isExternal mt={2}>
                    <Text
                      fontSize={"sm"}
                      fontWeight={"medium"}
                      cursor={"pointer"}
                    >
                      Apply Online
                    </Text>
                  </Link>
                )}
              </Flex>
            </Card>
          ))}
        </SimpleGrid>
      )}

      <ImageModal isOpen={isOpen} onClose={onClose} url={url} />
    </Container>
  );
};

const CustomLink = ({ icon, title, onClick, href, right }) => {
  return (
    <Flex gap={3} mt={2}>
      {icon}
      <Link
        href={href}
        flex={1}
        overflow={"hidden"}
        textOverflow={"clip"}
        noOfLines={1}
      >
        <Text
          fontSize={"sm"}
          fontWeight={"medium"}
          textOverflow={"ellipsis"}
          cursor={"pointer"}
          onClick={onClick}
        >
          {title}
        </Text>
      </Link>
      {right}
    </Flex>
  );
};

export default Claims;
