import {
  Center,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Image,
  Stack,
  Text,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import CustomInput from "./CustomInput";
import CustomButton1 from "./CustomButton1";
import { addQute } from "../utils/apis";
import { emailRegex, infoMess } from "../utils/utils";
import store from "../redux/Store";
import { getUserData } from "../redux/slice/userSlice";
import Select from "react-select";
import { useSelector } from "react-redux";
import SelectBox from "./SelectBox";
import { IoIosHelpCircle, IoMdHelp } from "react-icons/io";
import { IoHelp } from "react-icons/io5";

const AskExpert = ({ bg }) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const loggedInUser = localStorage.getItem("user");
  const user = JSON.parse(loggedInUser);
  const ID = user?.userid;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  const { user_data } = useSelector((state) => state.user);
  const [isSmallThan375] = useMediaQuery("(max-width: 375px)");


  // useEffect(() => {
  //   user === null ||
  //     store
  //       .dispatch(getUserData(ID))
  //       .unwrap()
  //       .then((v) => {
  //         v.success &&
  //           setData({
  //             ...data,
  //             Name: v.message.Name,
  //             Email: v.message.Email,
  //             mobile: v.message.mobile,
  //           });
  //       });
  // }, []);

  useEffect(() => {
    user === null ||
      setData({
        ...data,
        Name: user_data?.Name,
        Email: user_data?.Email,
        mobile: user_data?.mobile,
      });
  }, [user_data]);

  const sendQuote = () => {
    if (
      !data.type?.value?.trim() ||
      !data.Name?.trim() ||
      !data.Email?.trim() ||
      !data.mobile?.trim() ||
      !data.mess?.trim()
    ) {
      infoMess("all fields are required");
      return;
    }
    if (!emailRegex.test(data.Email?.trim())) {
      infoMess("Invalid email address");
      return;
    }
    if (data.mobile?.trim()?.length != 10) {
      infoMess("Invalid Mobile Number");
      return;
    }
    const char = data?.Name?.trim();
    const firstChar = char?.charAt(0);

    if (/^[^a-zA-Z]+$/.test(firstChar)) {
      infoMess(
        "Name could not contain special characters or spaces at the beginning"
      );
      return;
    }

    const body = new FormData();
    body.append("userid", ID);
    body.append("instype", data.type.value?.trim());
    body.append("name", data.Name?.trim());
    body.append("email", data.Email?.trim());
    body.append("contact", data.mobile?.trim());
    body.append("message", data.mess?.trim());
    body.append("policy", "expert");
    addQute(body, setLoading).then((v) => {
      v.success && onClose();
      v.success && setData({ ...data, type: {}, mess: "" });
    });
  };

  const checkKeyPress = useCallback(
    (e) => {
      const { key, keyCode } = e;
      if (keyCode === 13) {
        isOpen && sendQuote();
      }
    },
    [data]
  );

  useEffect(() => {
    window.addEventListener("keydown", checkKeyPress);
    return () => {
      window.removeEventListener("keydown", checkKeyPress);
    };
  }, [checkKeyPress]);
  return (
    <div>
      <Text
        bg={bg}
        paddingBlock={1}
        paddingInline={2}
        borderRadius={5}
        cursor={"pointer"}
        color={"#fff"}
        fontWeight={"bold"}
        onClick={() => onOpen()}
        fontSize={["14px", "sm", "md"]}
        display={["none", "flex"]}
      >
        Ask an Expert
      </Text>

      <Stack display={["flex", "none"]}>
        <IoMdHelp   size={25}
         onClick={() => onOpen()}
            style={{
              backgroundColor: "#fff",
              height: isSmallThan375 ? 20 : 30,
              width: isSmallThan375 ? 20 : 30,
              padding: 4,
              borderRadius: 20,
            }}/>
      </Stack>
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
        size={"md"}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Ask an Expert</DrawerHeader>
          <DrawerBody>
            <>
              <Text mb={0}>Insurance Type*</Text>
              <SelectBox
                placeholder={"Search and select Insurance Type"}
                value={data.type}
                onChange={(e) => setData({ ...data, type: e })}
                options={[
                  { label: "Health", value: "health" },
                  { label: "Motor", value: "motor" },
                  { label: "Life", value: "life" },
                  { label: "Other", value: "other" },
                ]}
              />
            </>

            <CustomInput
              label={"Full Name*"}
              value={data.Name}
              onChange={(v) => {
                // const inputValue = v.target.value.trim();
                // const firstChar = inputValue.charAt(0);

                // if (/^[a-zA-Z]+$/.test(firstChar) || inputValue.length === 0) {
                setData({ ...data, Name: v.target.value });
                // }
              }}
              placeholder={"Enter Full Name"}
            />

            <CustomInput
              label={"Email Address*"}
              value={data.Email}
              onChange={(e) => setData({ ...data, Email: e.target.value })}
              placeholder={"Enter Email Address"}
            />

            <CustomInput
              label={"Phone Number*"}
              value={data.mobile}
              onChange={(e) =>
                e.target.value.length < 11 &&
                setData({
                  ...data,
                  mobile: e.target.value.replace(/([a-zA-Z ])/g, ""),
                })
              }
              placeholder={"Enter Mobile Number"}
            />

            <CustomInput
              label={"Additional Information*"}
              value={data.mess}
              onChange={(e) => setData({ ...data, mess: e.target.value })}
              placeholder={"Enter Additional Information"}
              area
            />

            <Center mt={4} gap={10}>
              <CustomButton1
                bg={"#727b82"}
                title={"Cancel"}
                onClick={onClose}
              />
              <CustomButton1
                title={"Submit"}
                loading={loading}
                onClick={sendQuote}
              />
            </Center>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </div>
  );
};

export default AskExpert;
