import {
  Container,
  Flex,
  Grid,
  GridItem,
  Heading,
  ListItem,
  Spacer,
  UnorderedList,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import Popup from "../../components/Popup";
import { useSelector } from "react-redux";
import moment from "moment";
import store from "../../redux/Store";
import { getUserData, setSessionOut } from "../../redux/slice/userSlice";
import { homePageData } from "../../utils/utils";
import { ImgCompHome } from "../../components/ImgComp";
import { useNavigate } from "react-router-dom";
import { getNotifications } from "../../redux/slice/notificationSlice";
import homeVideo from "../../images/HomeVideo.mp4";
import { VscUnmute } from "react-icons/vsc";
import { VscMute } from "react-icons/vsc";
import { getPolicy_list } from "../../redux/slice/policySlice";
import { getDocument_list } from "../../redux/slice/DocumentSlice";
import { FaPlay } from "react-icons/fa";
import ImageModal from "../../components/ImageModal";
import { MarqueeText } from "../../components/MarqueText";
import { get_alerts } from "../../redux/slice/OtherSlice";

const Home = () => {
  const [isSmallThan500] = useMediaQuery("(max-width: 500px)");

  const [popup, setPopup] = useState(false);
  const { user_id } = useSelector((state) => state.user);
  const { video_list,alerts_list } = useSelector((state) => state.other);
  const [url, setUrl] = useState("");
  const {
    isOpen: isIOpen,
    onOpen: onIOpen,
    onClose: onIClose,
  } = useDisclosure();

  const navigate = useNavigate();

  useEffect(() => {
    store.dispatch(setSessionOut(true));
  }, []);

  useEffect(() => {
    store.dispatch(getUserData(user_id));
    // store.dispatch(getNotifications([user_id, ""]));
    store.dispatch(getPolicy_list(user_id));
    store.dispatch(getNotifications({ id: user_id }));
    store.dispatch(getDocument_list(user_id));
    store.dispatch(get_alerts(user_id));
  }, [user_id]);

  const { policy_list } = useSelector((state) => state.policy);
  const { document_list } = useSelector((state) => state.document);
  const { unread_policy_count, unread_document_count } = useSelector(
    (state) => state.notifications
  );
  const [countData, setCountData] = useState({
    policy: 0,
    document: document_list?.length,
  });

  const day1 = new Date();
  const day2 = new Date();
  const day7 = new Date();

  day1.setDate(day1.getDate() + 1);
  day2.setDate(day2.getDate() + 2);
  day7.setDate(day7.getDate() + 7);
  const policydata = policy_list.filter((item) => {
    return (
      moment(item.expiry_date).format("YYYY-MM-DD") ==
        moment(day1).format("YYYY-MM-DD") ||
      moment(item.expiry_date).format("YYYY-MM-DD") ==
        moment(day2).format("YYYY-MM-DD") ||
      moment(item.expiry_date).format("YYYY-MM-DD") ==
        moment(day7).format("YYYY-MM-DD")
    );
  });
  const document = document_list.filter((item) => {
    return (
      moment(item.expiry_date).format("YYYY-MM-DD") ==
        moment(day1).format("YYYY-MM-DD") ||
      moment(item.expiry_date).format("YYYY-MM-DD") ==
        moment(day2).format("YYYY-MM-DD") ||
      moment(item.expiry_date).format("YYYY-MM-DD") ==
        moment(day7).format("YYYY-MM-DD")
    );
  });

  const show = localStorage.getItem("popup");
  useEffect(() => {
    user_id &&
      show == "show" &&
      (policydata.length > 0 || document.length > 0) &&
      setPopup(true);
  }, [policydata, document]);

  useEffect(() => {
    store.dispatch(setSessionOut(true));
  }, []);

  const videoRef = useRef(null);
  const [isMuted, setIsMuted] = useState(true);

  const handleMuteUnmute = () => {
    videoRef.current.muted = !isMuted;
    setIsMuted(!isMuted);
  };
  useEffect(() => {
    filterCount();
  }, [policy_list]);

  const filterCount = async () => {
    const formats = ["DD/MM/YYYY", "YYYY/MM/DD", "YYYY-MM-DD", "DD-MM-YYYY"];
    const today = moment().startOf("day"); // Get today's date

    const count = policy_list.filter((v) => {
      let isValidExpiry = false;

      formats.forEach((format) => {
        const expiryDate = moment(v.expiry_date, format, true);
        if (expiryDate.isValid() && expiryDate.isSameOrAfter(today)) {
          isValidExpiry = true;
        }
      });

      return isValidExpiry;
    });

    setCountData({ ...countData, policy: count.length });
  };

  const [playIndex, setPlayIndex] = useState(null);
  const videoRefs = useRef([]);

  const handlePlayClick = (e, index) => {
    e.stopPropagation(); // Prevent event from bubbling up to the parent div
    if (videoRefs.current[index]) {
      videoRefs.current[index].play();
      setPlayIndex(index);
    }
  };

  const handlePauseClick = (e) => {
    e.stopPropagation(); // Prevent event from bubbling up to the parent div
    if (playIndex !== null && videoRefs.current[playIndex]) {
      videoRefs.current[playIndex].pause();
      setPlayIndex(null);
    }
  };

  return (
    <>
      <Container maxW={"full"} px={[1, 4, 6, 10]} py={[2, 10]} pt={[2, 5]}>
        <MarqueeText time={alerts_list?.length * 5}>
          {alerts_list?.map((v,i)=>`${i + 1}. ${v?.Message}. `)}
        </MarqueeText>
        <Heading
          fontSize={["md", "lg", "xl", "3xl"]}
          fontWeight={"medium"}
          color={"#e0a604"}
        >
          Hello User
        </Heading>
        <Spacer h={2} />

        <Heading
          fontSize={["md", "lg", "xl", "3xl"]}
          fontWeight={"medium"}
          color={"#fff"}
        >
          Welcome to the <span style={{ color: "#e0a604" }}>INSUGO DIGI</span>
        </Heading>
        {/* <Flex w={"full"} justifyContent={"center"} flexDir={"column"} alignItems={"center"}> */}
        <Grid
          maxW={"full"}
          pl={["0", "2%", "2%", "3%", "10%"]}
          pr={["0", "2%", "2%", "3%", "10%"]}
          // maxW={"1390px"}
          pt={["1rem", 0, 0, 0]}
          templateColumns={[
            "repeat(2, 1fr)",
            "repeat(2, 1fr)",
            "repeat(2, 1fr)",
            "repeat(3, 1fr)",
          ]}
          gap={0}
          alignItems="center"
          justifyContent="space-around"
        >
          {homePageData?.map((v, i) => (
            <GridItem
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              w={"full"}
              h={"fit-content"}
            >
              <ImgCompHome
                h={["170px", "200px", "240px", "240px", "240px"]}
                w={["167px", "190px", "230px", "260px", "265px"]}
                br={"10px"}
                notification={
                  v?.lable == "Existing Policies" || v.lable == "Documents"
                    ? true
                    : false
                }
                reDirect={() =>
                  navigate("/all-notifications", {
                    state: {
                      type:
                        v?.lable == "Insurance Policies"
                          ? "policy"
                          : v.lable == "Documents"
                          ? "document"
                          : "",
                    },
                  })
                }
                data_count={
                  v?.lable == "Insurance Policies"
                    ? countData?.policy
                    : v.lable == "Documents"
                    ? document_list?.length
                    : ""
                }
                count={
                  v?.lable == "Insurance Policies"
                    ? unread_policy_count
                    : v.lable == "Documents"
                    ? unread_document_count
                    : 0
                  // 3
                }
                p={["2px", "10px"]}
                border={"2px"}
                borderColor={"rgba(0, 0, 0, 0.75)"}
                imgW={["100px", "120px", "130px", "140px", "145px"]}
                imgH={["100px", "120px", "130px", "140px", "145px"]}
                bg={"rgb(172,183,184,.6)"}
                color={"#fff"}
                key={i}
                img={v?.img}
                title={v?.lable}
                onClick={() => navigate(v?.path)}
              />
            </GridItem>
          ))}
        </Grid>

        <Flex
          className="videos_main_div"
          // maxW={"1390px"}
        >
          {video_list?.map((v, i) => (
            <div
              key={i}
              style={{
                position: "relative",
                // width: video_list?.length === 1 ? "72%" : "100%",
                padding:
                  video_list?.length === 1
                    ? isSmallThan500
                      ? "0 0"
                      : "0 14%"
                    : "0 0",
                minWidth: "350px",
                maxHeight: "600px",
                minHeight: "600px",
              }}
              className="video-main"
              onClick={handlePauseClick}
            >
              <video
                ref={(el) => (videoRefs.current[i] = el)}
                style={{
                  width: "100%",
                  height: "100%",
                  minHeight: "600px",
                  maxHeight: "600px",
                  cursor: playIndex === i ? "pointer" : "",
                }}
                autoPlay={playIndex === i}
                loop
                muted={!(playIndex === i)}
                className="background-video"
              >
                <source src={v?.Video_url} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              {playIndex !== i && (
                <div
                  className="play-icon"
                  // onClick={(e) => handlePlayClick(e, i)}
                  onClick={() => {
                    setUrl(v?.Video_url);
                    onIOpen();
                  }}
                >
                  <FaPlay
                    cursor={"pointer"}
                    style={{ paddingLeft: "3px" }}
                    size={24}
                    color="rgb(255,255,255,.8)"
                  />
                </div>
              )}
            </div>
          ))}
        </Flex>
        {/* </Flex> */}

        <Popup
          open={popup ? true : false}
          setOpen={() => {
            localStorage.removeItem("popup");
            setPopup(false);
          }}
          title={"Alert"}
          mess={
            <UnorderedList>
              {policydata.map((v, i) => (
                <ListItem key={i}>
                  {`your ${v.insurance_type} insurance with ${
                    v.policy_company
                  } is about to expire in ${
                    (moment(v.expiry_date).format("YYYY-MM-DD") ==
                      moment(day1).format("YYYY-MM-DD") &&
                      "1 day") ||
                    (moment(v.expiry_date).format("YYYY-MM-DD") ==
                      moment(day2).format("YYYY-MM-DD") &&
                      "2 days") ||
                    (moment(v.expiry_date).format("YYYY-MM-DD") ==
                      moment(day7).format("YYYY-MM-DD") &&
                      "7 days")
                  }
                `}
                </ListItem>
              ))}
              {document.map((v, i) => (
                <ListItem key={i}>
                  {`your document ${v.doc_type} is due to expire in ${
                    (moment(v.expiry_date).format("YYYY-MM-DD") ==
                      moment(day1).format("YYYY-MM-DD") &&
                      "1 day") ||
                    (moment(v.expiry_date).format("YYYY-MM-DD") ==
                      moment(day2).format("YYYY-MM-DD") &&
                      "2 days") ||
                    (moment(v.expiry_date).format("YYYY-MM-DD") ==
                      moment(day7).format("YYYY-MM-DD") &&
                      "7 days")
                  }
                `}
                </ListItem>
              ))}
            </UnorderedList>
          }
        />
        <ImageModal isOpen={isIOpen} onClose={onIClose} url={url} />
      </Container>
    </>
  );
};

export default Home;

// <div
// key={i}
//   style={{
//     position: "relative",
//     width: video_list?.length == 1 ? "72%" : "100%",
//   }}
// >
//   <video
//     ref={videoRef}
//     autoPlay
//     loop
//     className="background-video"
//     muted={isMuted}
//   >
//     <source src={v?.Video_url} type="video/mp4" />
//     Your browser does not support the video tag.
//   </video>
//   <div className="controls">
//     <button onClick={handleMuteUnmute}>
//       {isMuted ? <VscMute size={20} /> : <VscUnmute size={20} />}
//     </button>
//   </div>
// </div>
