import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { send_token } from "./utils/apis";

const firebaseConfig = {
  apiKey: "AIzaSyDhk63TXpNuvgjfHpll-QFO2zlEOa9EOiE",
  authDomain: "insugo-digi.firebaseapp.com",
  projectId: "insugo-digi",
  storageBucket: "insugo-digi.appspot.com",
  messagingSenderId: "419892778919",
  appId: "1:419892778919:web:36e88f4583167a72738882",
  measurementId: "G-21YRBN9L1S",
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

const loggedInUser = localStorage.getItem("user");
const user = JSON.parse(loggedInUser);

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

export const getDToken = async () => {
  try {
    const currentToken = await getToken(messaging, {
      vapidKey:
        "BEYtS6g1Bjq6jR55FRJFEK6oqUGcZLZ4Ol4Aswvy40HPLIzSAjnGNEP-GiR4ZriZK9YLMz7eo0wMLAKMd7mZ9nk",
    });

    if (currentToken) {
      localStorage.setItem("fbToken", currentToken);
    } else {
      // console.log("error","something Went Wrong");
    }
  } catch (err) {
    console.log("An error occurred while retrieving token.", err);
  }
};
